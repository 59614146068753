<template>
    <div class="row">
        <div class="col-md-3">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group" id="datForm">
                        <label class="control-label tx-bold">Fecha De Registro Inventario</label>
                        <date-picker v-model="time1" valueType="YYYY-MM-DD" range @input="changeValue"></date-picker>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label><b>Supervisor</b></label>
                    <gestores-component :placeholderm="'Supervisor'" v-on:cambioGestor="setGestor" ref="gestorSelect"></gestores-component>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label><b>Tienda</b></label>
                    <tiendas-select :placeholderm="'Tienda'" ref="Tienda" v-on:cambioTienda="changeValue"></tiendas-select>
                </div>
            </div><br><br>
            <div class="row">
                <div class="col-md-12">
                    <button type="button" class="btn ripple btn-primary btn-lg btn-block" @click="consultarInventario">Ver Registros <i class="fas fa-binoculars"></i></button>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="row" v-if="showLoad">
                <div class="col-md-12 text-center"><br><br>
                    <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                    <p><b>Buscando información por favor espere!</b></p>
                </div>
            </div>
            <div class="row" v-show="showData">
                <div :class="{'col-md-12':!showImage,'col-md-8':showImage}">
                    <div class="row">
                        <div class="col-md-2">
                            <button v-show="!newRegistro" class="btn ripple btn-success" @click="newRegistro=true">Agregar <i class="fas fa-plus"></i></button>
                            <button v-show="newRegistro" class="btn ripple btn-success" @click="saveNewRegistro">Registrar <i class="fas fa-save"></i></button><br><br>
                            <button v-show="newRegistro" class="btn ripple btn-danger" @click="cancelNewRegistro">Cancelar <i class="fas fa-times"></i></button>
                        </div>
                        <div class="col-md-5" v-show="newRegistro">
                            <productos-select :placeholderm="'Producto'" ref="newProducto"></productos-select>
                        </div>
                        <div class="col-md-2" v-show="newRegistro">
                            <input type="number" min="0" max="10000" step="1" class="form-control" v-model="nuevaCantidad" placeholder="Cantidad" onkeydown="return event.keyCode !== 69">
                        </div>
                        <div class="col-md-3" v-show="newRegistro">
                            <input type="text" maxlength="250" class="form-control" v-model="nuevoComentario" placeholder="Ingresse comentario">
                        </div>
                    </div>
                    <v-client-table :data="tableDataInventario" :columns="columnsInventario" :options="optionsInventario" class="table-responsive">
                        <div slot="Pathimg" slot-scope="props" class="text-center">
                            <i v-if="props.row.Pathimg !== null " class="fas fa-eye" style="cursor:pointer; color: green;" @click="showImages(props.row.Pathimg)"></i>
                            <i v-else class="fas fa-eye-slash" style="color: red;" ></i>
                        </div>
                        <div slot="Cntreginv" slot-scope="props" class="text-center">
                            <span style="cursor:pointer" @click="editCnt(props.row.Id,'edit')">{{props.row.Cntreginv}}</span>
                            <div v-if="props.row.Edit === 1">
                                <input class="form-control" type="number" min="0" max="10000" step="1" placeholder="Actualizar" v-model="newCantidad">
                                <i class="fas fa-save" style="color: green;cursor:pointer;" @click="saveCnt(props.row.Id)"></i> &nbsp;
                                <i class="fas fa-times" style="color: red;cursor:pointer;" @click="editCnt(props.row.Id,'cancel')"></i>
                            </div>
                        </div>
                        <div slot="Comentario" slot-scope="props" class="text-center">
                            <span style="cursor:pointer" @click="editComentarios(props.row.Id,'edit')">{{props.row.Comentario}}</span>
                            <div v-if="props.row.EditComentario === 1">
                                <input class="form-control" type="text" maxlength="250" step="1" placeholder="Actualizar" v-model="newComentario">
                                <i class="fas fa-save" style="color: green;cursor:pointer;" @click="saveComentario(props.row.Id)"></i> &nbsp;
                                <i class="fas fa-times" style="color: red;cursor:pointer;" @click="editComentarios(props.row.Id,'cancel')"></i>
                            </div>
                        </div>
                    </v-client-table>
                </div>
                <div v-if="showImage" class="col-md-4 text-center">
                    <h4><i class="fas fa-times" @click="showImage=false" style="color: red;cursor:pointer"></i></h4>
                    <transition name="fade">
                        <img :src="pathImg" alt="img Producto" class="rounded mx-auto d-block" >
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "registerInventario",
    data(){
        return{
            newRegistro: false,
            editCantidad: false,
            editComentario: false,
            newCantidad:0,
            newComentario:'',
            pathImg:'',
            showImage:false,
            time1:null,
            supervisor:null,
            showLoad:false,
            showData:false,
            columnsInventario: ['Id','Cdgentpto','Producto','Fecha','Hora','Cntreginv','Comentario','Pathimg'],
            tableDataInventario: [],
            optionsInventario: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [2,5,10,15,25,45,50],
                filterable: ['Cdgentpto','Producto'],
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    Cntreginv: 'Piezas',
                    Pathimg: 'Imagen',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Cntreginv: 'text-center font-bold text-info',
                    Cdgentpto: 'text-center',
                    Hora: 'text-center',
                    Fecha: 'text-center',
                    Comentario: 'text-justify',
                    Producto: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            nuevaCantidad:1,
            nuevoComentario:''
        }
    },
    mounted() {
    },
    methods:{
        saveNewRegistro(){ let $this = this;
            if (this.$refs.newProducto.selectedProductos === null){
                this.$toastMessage('error',"Seleccione Producto",3000,'top-right');
            }
            else if( this.nuevaCantidad === 0 || this.nuevaCantidad < 0 ){
                this.$toastMessage('error',"Ingrese una cantidad valida",3000,'top-right');
            }
            else if (this.nuevoComentario === '' || this.nuevoComentario.length < 3){
                this.$toastMessage('error',"Ingrese un comentario valido mayor a 3 caracteres",3000,'top-right');
            }
            else{
                axios.post('/api/Inventarios/peticiones',{solicitud:4,comentario:this.nuevoComentario,cantidad:this.nuevaCantidad,
                    producto:this.$refs.newProducto.selectedProductos.value,tienda:this.$refs.Tienda.selectedTiendas.value,
                user:this.$refs.gestorSelect.selectedGestores.value})
                .then(function (r){
                    if (r.data.Success){
                        $this.consultarInventario();
                        $this.$toastMessage('success',r.data.Mensaje,3000,'center');
                        $this.cancelNewRegistro();
                    }
                    else{
                        $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                    }
                });
            }
        },
        cancelNewRegistro(){
            this.newRegistro = false;
            this.nuevaCantidad= 1;
            this.nuevoComentario= '';
            this.$refs.newProducto.selectedProductos = null;
        },
        changeValue(){
            this.showImage = false;
            this.showData = false;
            this.tableDataInventario = [];
            this.cancelNewRegistro();
        },
        saveCnt(id){ let $this = this;
            var idx = this.findIdx(id);
            axios.post('/api/Inventarios/peticiones',{solicitud:2,cantidad:this.newCantidad,clave:id})
                .then(function (r){
                    if (r.data.Success){
                        $this.tableDataInventario[idx].Cntreginv = $this.newCantidad;
                        $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                        $this.editCnt(id,'cancel');
                    }
                    else{
                        $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                    }
                });
        },
        saveComentario(id){ let $this = this;
            var idx = this.findIdx(id);
            axios.post('/api/Inventarios/peticiones',{solicitud:3,comentario:this.newComentario,clave:id})
            .then(function (r){
                if (r.data.Success){
                    $this.tableDataInventario[idx].Comentario = $this.newComentario;
                    $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                    $this.editComentarios(id,'cancel');
                }
                else{
                    $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                }
            });
        },
        editCnt(id,type){
            var idx = this.findIdx(id);
            if (type === 'edit'){
                if (this.editCantidad){
                    this.$toastMessage('warning','Actualización en proceso',3000,'center');
                }
                else{
                    this.tableDataInventario[idx].Edit = 1;
                    this.editCantidad = true;
                    this.newCantidad = this.tableDataInventario[idx].Cntreginv;
                }
            }
            else{
                this.tableDataInventario[idx].Edit = 0;
                this.editCantidad = false;
            }
        },
        editComentarios(id,type){
            var idx = this.findIdx(id);
            if (type === 'edit'){
                if (this.editComentario){
                    this.$toastMessage('warning','Actualización en proceso',3000,'center');
                }
                else{
                    this.tableDataInventario[idx].EditComentario = 1;
                    this.editComentario = true;
                    this.newComentario= this.tableDataInventario[idx].Comentario;
                }
            }
            else{
                this.tableDataInventario[idx].EditComentario = 0;
                this.editComentario = false;
            }
        },
        showImages(path){
            //console.log(path);
            let $this = this;
            this.showImage = true;
            this.pathImg='/theme/img/loads/pelota.gif';
            setTimeout(function(){
                $this.pathImg = path;
            }, 700);
        },
        setGestor(){
            if (this.$refs.gestorSelect.selectedGestores !== null){
                this.supervisor = this.$refs.gestorSelect.selectedGestores.value;
                this.changeValue();
            }
            else{
                this.supervisor = null;
                this.changeValue();
            }
        },
        validateTime(){
            if (this.time1 === null ){
                return true;
            }
            else{
                var a = false;
                this.time1.forEach(function (value){
                    if (value == null){
                        a = true;
                    }
                });
                return a;
            }
        },
        consultarInventario(){
            var a = this.$refs.Tienda.selectedTiendas;
            if (this.time1 === null || this.validateTime()){
                this.$toastMessage('error',"Selecciona la fecha del reporte",3000,'top-right');
            }
            else if (this.supervisor === null){
                this.$toastMessage('error',"Selecciona el supervisor",3000,'top-right');
            }
            else if(a === null){
                this.$toastMessage('error',"Selecciona la  tienda",3000,'top-right');
            }
            else{ let $this = this;
                this.showLoad = true;
                this.showData = false; this.tableDataInventario = [];
                axios.post('/api/Inventarios/peticiones',{fecha:this.time1,usuario:this.supervisor,tienda:a.value,solicitud:1})
                .then(function (r){
                    if (r.data.Success){
                        $this.showData = true;
                        $this.showLoad = false;
                        $this.tableDataInventario = r.data.Data;
                        $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                    }
                    else{
                        $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                    }
                });
            }
        },
        findIdx(findId){let $this = this;
            var idn = 0;
            var a = 0;
            for(a;a<$this.tableDataInventario.length;a++){
                if ($this.tableDataInventario[a].Id === findId){
                    idn = a;
                }
            }
            return idn;
        },
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
}
</style>
