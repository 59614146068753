<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <div class="text-center">
                    <div class="row">
                        <div class="col-md-10">
                            <h3>FABRICANTES</h3>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-success dim text-center" type="button"
                                @click="shoModalFab=true"    data-effect="effect-just-me" data-toggle="modal" href="#myModalFab">
                                <i class="fa fa-plus-circle"></i>
                            </button>
                        </div>
                    </div>
                </div><br><br>
                <v-client-table :data="tableDataFabricante" :columns="columnsFabricante" :options="optionsFabricante" class="table-responsive">

                </v-client-table>
            </div>
            <div class="col-md-3">
                <div class="text-center">
                    <div class="row">
                        <div class="col-md-10">
                            <h3>MARCAS</h3>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-success dim text-center" type="button"
                                    @click="shoModalMar=true"    data-effect="effect-just-me" data-toggle="modal" href="#myModalMar">
                                <i class="fa fa-plus-circle"></i>
                            </button>
                        </div>
                    </div>
                </div><br><br>
                <v-client-table :data="tableDataMarcas" :columns="columnsMarcas" :options="optionsMarcas" class="table-responsive">

                </v-client-table>
            </div>
            <div class="col-md-3">
                <div class="text-center">
                    <div class="row">
                        <div class="col-md-10">
                            <h3>DEPARTAMENTOS</h3>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-success dim text-center" type="button"
                                    @click="shoModalDep=true" data-effect="effect-just-me" data-toggle="modal" href="#myModalDep">
                                <i class="fa fa-plus-circle"></i>
                            </button>
                        </div>
                    </div>
                </div><br><br>
                <v-client-table :data="tableDataDepartamentos" :columns="columnsDepartamentos" :options="optionsDepartamentos" class="table-responsive">
                </v-client-table>
            </div>
            <div class="col-md-3">
                <div class="text-center">
                    <div class="row">
                        <div class="col-md-10">
                            <h3>CATEGORIAS</h3>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-success dim text-center" type="button"
                                    @click="shoModalCat=true" data-effect="effect-just-me" data-toggle="modal" href="#myModalCat">
                                <i class="fa fa-plus-circle"></i>
                            </button>
                        </div>
                    </div>
                </div><br><br>
                <v-client-table :data="tableDataCategorias" :columns="columnsCategorias" :options="optionsCategorias" class="table-responsive">
                </v-client-table>
            </div>
        </div>
        <!-- MODAL FABRICANTES -->
        <div class="modal" id="myModalFab" tabindex="-1" :class="{'show':shoModalFab}">
            <div class="modal-dialog modal-dialog-centered modal-lg " role="document" >
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">ALTA DE FABRICANTE</h6><button id="closeFab" aria-label="Close" class="close" data-dismiss="modal" type="button" @click="cancelAlta"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <p><b>Fabricante</b></p>
                                <input type="text" maxlength="50" placeholder="Ingrese el fabricante"  class="form-control" v-model="nuevoFabricante"/>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                        <br>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="cancelAlta('Fab')"> Cancelar <i class="fa fa-times"></i></button>
                        <button type="button" class="btn btn-success" @click="saveAlta"> Registrar  <i class="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL MARCAS -->
        <div class="modal" id="myModalMar" tabindex="-1" :class="{'show':shoModalMar}">
            <div class="modal-dialog modal-dialog-centered modal-lg " role="document" >
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">ALTA DE MARCA</h6><button id="closeMar" aria-label="Close" class="close" data-dismiss="modal" type="button" @click="cancelAlta"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4">
                                <p><b>Fabricante</b></p>
                                <fabricantes-select :placeholderm="'Fabricante'" ref="Fabricantes"></fabricantes-select>
                            </div>
                            <div class="col-md-4">
                                <p><b>Marca</b></p>
                                <input type="text" maxlength="50" placeholder="Ingrese la marca"  class="form-control" v-model="nuevaMarca"/>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                        <br>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="cancelAlta('Mar')"> Cancelar <i class="fa fa-times"></i></button>
                        <button type="button" class="btn btn-success" @click="saveAlta"> Registrar  <i class="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL DEPARTAMENTOS -->
        <div class="modal" id="myModalDep" tabindex="-1" :class="{'show':shoModalDep}">
            <div class="modal-dialog modal-dialog-centered modal-lg " role="document" >
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">ALTA DE DEPARTAMENTO</h6><button id="closeDep" aria-label="Close" class="close" data-dismiss="modal" type="button" @click="cancelAlta"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <p><b>Departamento</b></p>
                                <input type="text" maxlength="50" placeholder="Ingrese el departamento"  class="form-control" v-model="nuevoDepartamento"/>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                        <br>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="cancelAlta('Dep')"> Cancelar <i class="fa fa-times"></i></button>
                        <button type="button" class="btn btn-success" @click="saveAlta"> Registrar  <i class="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL CATEGORIAS -->
        <div class="modal" id="myModalCat" tabindex="-1" :class="{'show':shoModalCat}">
            <div class="modal-dialog modal-dialog-centered modal-lg " role="document" >
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">ALTA DE CATEGORIA</h6><button id="closeCat" aria-label="Close" class="close" data-dismiss="modal" type="button" @click="cancelAlta"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4">
                                <p><b>Departamento</b></p>
                                <departamentos-select :placeholderm="'Departamento'" ref="Departamentos"></departamentos-select>
                            </div>
                            <div class="col-md-4">
                                <p><b>Categoria</b></p>
                                <input type="text" maxlength="50" placeholder="Ingrese la categoria"  class="form-control" v-model="nuevaCategoria"/>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                        <br>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="cancelAlta('Cat')"> Cancelar <i class="fa fa-times"></i></button>
                        <button type="button" class="btn btn-success" @click="saveAlta"> Registrar  <i class="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "catalogospto",
    data(){
        return{
            nuevaMarca:'',
            nuevaCategoria:'',
            nuevoFabricante:'',
            nuevoDepartamento:'',
            shoModalFab:false,
            shoModalMar:false,
            shoModalDep:false,
            shoModalCat:false,
            columnsFabricante: ['Cveentfab','Nomentfab'],
            tableDataFabricante: [],
            optionsFabricante: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Nomentfab'],
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Cveentfab: '#',
                    Nomentfab: 'Canal',
                },
                columnsClasses: {
                    Cveentfab: 'text-center font-bold text-danger',
                    Nomentfab: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsMarcas: ['Cveentmar','Nomentmar','Nomentfab'],
            tableDataMarcas: [],
            optionsMarcas: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Nomentmar','Nomentfab'],
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Cveentmar: '#',
                    Nomentmar: 'Marca',
                    Nomentfab: 'Fabricante',
                },
                columnsClasses: {
                    Cveentmar: 'text-center font-bold text-danger',
                    Nomentmar: 'text-justify',
                    Nomentfab: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsDepartamentos: ['Cveentdep','Nomentdep'],
            tableDataDepartamentos: [],
            optionsDepartamentos: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Nomentdep'],
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Cveentdep: '#',
                    Nomentdep: 'Depaartamento',
                },
                columnsClasses: {
                    Cveentdep: 'text-center font-bold text-danger',
                    Nomentmar: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsCategorias: ['Cveentcat','Nomentcat','Nomentdep'],
            tableDataCategorias: [],
            optionsCategorias: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Nomentcat','Nomentdep'],
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Cveentcat: '#',
                    Nomentdep: 'Depaartamento',
                    Nomentcat: 'Categoria',
                },
                columnsClasses: {
                    Cveentcat: 'text-center font-bold text-danger',
                    Nomentcat: 'text-justify',
                    Nomentdep: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        }
    },
    mounted() {
        this.getData();
    },
    methods:{
        cancelAlta(type){
            if (type === 'Fab')
            {
                this.shoModalFab = !this.shoModalFab;
                this.nuevoFabricante = '';
                document.getElementById('closeFab').click();
            }
            if (type === 'Mar')
            {
                this.shoModalMar = !this.shoModalMar;
                this.nuevaMarca = '';
                document.getElementById('closeMar').click();
            }
            if (type === 'Dep')
            {
                this.shoModalDep = !this.shoModalDep;
                this.nuevaMarca = '';
                document.getElementById('closeDep').click();
            }
            if (type === 'Cat')
            {
                this.shoModalCat = !this.shoModalCat;
                this.nuevaCategoria = '';
                document.getElementById('closeCat').click();
            }
        },
        saveAlta(){
            if (this.shoModalFab){
                if (this.nuevoFabricante.length<=3){
                    this.$toastMessage('error','Ingrese un nombre de fabricante valido!',3000,'top-right');
                }
                else{
                    this.sendSave(4,this.nuevoFabricante,'');
                }

            }
            if (this.shoModalMar){
                var a = this.$refs.Fabricantes.selectedFabricantes;
                if (this.nuevaMarca.length<=3){
                    this.$toastMessage('error','Ingrese un nombre de la marca valido!',3000,'top-right');
                }else if (a === null){
                    this.$toastMessage('error','Seleccione un fabricante!',3000,'top-right');
                }
                else{
                    this.sendSave(5,this.nuevaMarca,a);
                }
            }
            if (this.shoModalDep){
                if (this.nuevoDepartamento.length<=3){
                    this.$toastMessage('error','Ingrese un nombre de departamento valido!',3000,'top-right');
                }
                else{
                    this.sendSave(6,this.nuevoDepartamento,'');
                }

            }
            if (this.shoModalCat){
                var a = this.$refs.Departamentos.selectedDepartamentos;
                if (this.nuevaCategoria.length<=3){
                    this.$toastMessage('error','Ingrese un nombre de categoria valido!',3000,'top-right');
                }else if (a === null){
                    this.$toastMessage('error','Seleccione una categoria!',3000,'top-right');
                }
                else{
                    this.sendSave(7,this.nuevaCategoria,a);
                }
            }


        },
        sendSave(solicitud,varst,fab){ let $this = this;
            axios.post('/api/Productos/peticiones',{solicitud: solicitud, variable: varst, fabricante: fab})
                .then(function (r){
                    if (r.data.Success){
                        if (solicitud === 4){
                            $this.cancelAlta('Fab');
                            $this.$refs.Fabricantes.getFabricantes();
                        }
                        if (solicitud === 5){
                            $this.cancelAlta('Mar');
                        }
                        if (solicitud === 6){
                            $this.cancelAlta('Dep');
                        }
                        if (solicitud === 7){
                            $this.cancelAlta('Cat');
                        }
                        $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                        $this.getData();
                    }
                    else{
                        $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');

                    }
                });
        },
        changeValueTalbe(cve,table){
            console.log(cve);
            console.log(table);
        },
        getData(){ let $this = this;
            this.tableDataFabricante=[];
            this.tableDataMarcas=[];
            this.tableDataDepartamentos=[];
            this.tableDataCategorias=[];
            axios.post('/api/Productos/peticiones',{solicitud:1})
            .then(function (r){
                if (r.data.Success){
                    $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                    $this.tableDataFabricante = r.data.Fabricantes;
                    $this.tableDataMarcas = r.data.Marcas;
                    $this.tableDataDepartamentos = r.data.Departamentos;
                    $this.tableDataCategorias = r.data.Categorias;
                }
                else{
                    $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                }
            });
        },
        newFabricante(){

        },
        newMarca(){

        },
        newDepartamentos(){

        },
        newCategorias(){

        }
    }
}
</script>

<style scoped>

</style>
