<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <button v-if="typeUsers===1" type="button" class="btn ripple btn-outline-danger" @click="getData(0)" ><i class="fe fe-eye"></i> Inactivos</button>
                <button v-if="typeUsers===0" type="button" class="btn ripple btn-outline-success" @click="getData(1)" ><i class="fe fe-eye"></i> Activos</button>
            </div>
            <div class="col-md-3">
                <button type="button" class="modal-effect btn ripple btn-outline-info"  data-effect="effect-super-scaled" data-toggle="modal" href="#modalnewUser"><i class="fe fe-user-plus"></i> Nuevo Usuario</button>
                <button style="display:none" type="button" class="modal-effect btn ripple btn-outline-info"  data-effect="effect-super-scaled" data-toggle="modal" href="#modalEditUser"><i class="fe fe-user-x" ref="buttonModalEdit"></i> Editar Usuario</button>
            </div>
            <div class="col-md-3">
                <button type="button" class="btn ripple btn-outline-primary" @click="DeleteAllSeriales"><i class="fe fe-trash-2"></i> Eliminar Seriales</button>
            </div>
            <div class="col-md-3">
                <button type="button" class="btn ripple btn-outline-success" @click="ExportFile"><i class="far fa-file-excel"></i> Exportar</button>
                <div class="row" v-if="showLoadDownload">
                    <div class="col-md-12 text-center"><br><br>
                        <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                        <p><b>Descargando información!</b></p>
                    </div>
                </div>
            </div>
        </div><br><br>
        <v-client-table :data="tableData" :columns="columns" :options="options" ref="UsersTable" v-show="!showLoad">
            <div slot="Stt" slot-scope="props">
                <span v-if="props.row.Stt === 'Activo'" class="badge badge-success ripple" @click="ChangeStatus(props.row.Cveentusr,0)">Activo</span>
                <span v-else class="badge badge-danger ripple" @click="ChangeStatus(props.row.Cveentusr,1)">Inactivo</span>
            </div>
            <div slot="Accion" slot-scope="props">
                <i class="fa fa-lock ripple" style="cursor:pointer;color: #fad208" data-placement="top" data-toggle="tooltip-primary" title="Cambiar Contraseña" data-original-title="Cambiar Contraseña" @click="changePass(props.row.Cveentusr)"></i>
                <i class="fa fa-eraser ripple" style="cursor:pointer;color: #ef0a0a" data-placement="top" data-toggle="tooltip-primary" title="Eliminar Dispositivo" data-original-title="Eliminar Dispositivo" @click="DeleteSerial(props.row.Cveentusr,props.row.Serial)"></i>
                <i class="fa fa-user-edit ripple" style="cursor:pointer;color: #0264f8" data-placement="top" data-toggle="tooltip-primary" title="Editar Usuario" data-original-title="Editar Usuario" @click="editUser(props.row.Cveentusr)"></i>
            </div>
            <div slot="Serial" slot-scope="props">
                <small>{{props.row.Serial}}</small>
            </div>
            <div slot="Fchcrt" slot-scope="props">
                <small>{{props.row.Fchcrt}}</small>
            </div>
            <div slot="FU" slot-scope="props">
                <small>{{props.row.FU}}</small>
            </div>
            <div slot="Tipo" slot-scope="props">
                <small>{{props.row.Tipo}}</small><br>
                <small><i class="fa fa-edit ripple text-info" v-if="!props.row.Edit" @click="startEditTipo(props.row.Cveentusr)"></i></small>
                <small><i class="fa fa-save ripple text-success" v-if="props.row.Edit" @click="saveTipo(props.row.Cveentusr)"></i>&nbsp;&nbsp;&nbsp;</small>
                <small><i class="fa fa-times ripple text-danger" v-if="props.row.Edit" @click="cancelEditTipo(props.row.Cveentusr)"></i></small><br>
                <select :ref="'opcionTipo'+props.row.Cveentusr" v-if="props.row.Edit" class="form-control">
                    <option v-for="items in optionsTipo" :value="items.value">{{items.title}}</option>
                </select>
            </div>
            <div slot="Ciudad" slot-scope="props">
                <small>{{props.row.Ciudad}}</small>
            </div>
            <div slot="Agencia" slot-scope="props">
                <small>{{props.row.Agencia}}</small>
            </div>
            <div slot="Usrentusr" slot-scope="props">
                <small>{{props.row.Usrentusr}}<br>{{props.row.Telefono}}</small>
            </div>
            <div slot="Nombre" slot-scope="props">
                <small>{{props.row.Nombre}}</small>
            </div>
            <div slot="Permisos" slot-scope="props">
                <ul>
                    <li v-for="items in props.row.Permisos">
                        <small>{{permisos[items]}}</small>
                    </li>
                </ul>
                <div class="text-center" v-if="tiposPermitidos.includes(props.row.Tipo)">
                    <small>
                        <i class="fas fa-edit text-info" style="cursor: pointer"
                           @click="changePermisos(props.row.Cveentusr)"
                           data-effect="effect-super-scaled" data-toggle="modal" href="#modalAddPermisos"></i>
                    </small>
                </div>
            </div>
            <div slot="Rol" slot-scope="props">
                <small>{{props.row.Rol}}</small><br>
                <small><i class="fa fa-edit ripple text-info" v-if="!props.row.EditRol && valideteRolAPK(props.row.Tipo)" @click="startEditRol(props.row.Cveentusr)"></i></small>
                <small><i class="fa fa-save ripple text-success" v-if="props.row.EditRol && valideteRolAPK(props.row.Tipo)" @click="saveRol(props.row.Cveentusr)"></i>&nbsp;&nbsp;&nbsp;</small>
                <small><i class="fa fa-times ripple text-danger" v-if="props.row.EditRol && valideteRolAPK(props.row.Tipo)" @click="cancelEditRol(props.row.Cveentusr)"></i></small><br>
                <select :ref="'opcionRol'+props.row.Cveentusr" v-if="props.row.EditRol && valideteRolAPK(props.row.Tipo)" class="form-control">
                    <option v-for="items in optionsRol" :value="items.value">{{items.text}}</option>
                </select>
            </div>
        </v-client-table>
        <div class="row" v-if="showLoad">
            <div class="col-md-12 text-center"><br><br>
                <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                <p><b>Buscando información por favor espere!</b></p>
            </div>
        </div>
        <modalnewuser-component ref="newUserModal" v-on:myEvent="getData(1)"></modalnewuser-component>
        <modaleditser-component ref="editUserModal" v-on:editFinish="endEdit()"></modaleditser-component>
        <addpermisos-component ref="addPermisos"></addpermisos-component>
    </div>
</template>

<script>
import editPermisos from './editPermisos';
export default {
    components:{
        'addpermisos-component':editPermisos,
    },
name: "tableUsers",
    data(){
        return{
            permisos:["","Prioridades","Faltantes","Promociones","Anaquel","Exhibiciones","Precios","Gráfico","Comentarios","Captura Rápido",'Campañas','Ventasa','Inventario'],
            tiposPermitidos: ['STORE OWNER','MERCHANDISER','PROMO&MERCHANDISER','AMBASSADOR','AMBASSADOR RUTA','COORDINADOR','SAMPLER'],
            tipoUserEdit: {},
            rolUserEdit: {},
            typeUsers:1,
            msg:"asdf",
            showLoad:true,
            showLoadDownload:false,
            columns: ['Idemp', 'Nombre','Usrentusr','Agencia','Stt','Ciudad','Tipo','Rol','Permisos','Fchcrt','Serial','Verapp','FU','Accion'],
            tableData: [],
            optionsTipo: [],
            optionsTiposApp: [],
            optionsRol: [],
            options: {
                // see the options API
                footerHeadings:false,
                filterByColumn:true,
                perPage:10,
                perPageValues: [10,15,25,45,50],
                filterable: ['Nombre','Usrentusr','Agencia','Ciudad','Tipo','Rol'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                sortable: ['Nombre','Usrentusr','Agencia','Ciudad','Tipo','Rol','FU','Fchcrt'],
                listColumns: {Agencia: [], Tipo: [], Rol: [],},
                headings: {
                    Idemp: '#',
                    Nombre: 'Nombre Empleado',
                    Agencia: 'Agencia',
                    Stt: 'Estatus',
                    Verapp: 'Ver App',
                    FU: 'Fecha Update App',
                    Fchcrt: 'Fecha Alta',
                    Rol: 'Rol',
                    Accion: 'Acciones',
                    Usrentusr: 'Usuario',
                    Permisos: 'Permisos Adicionales',
                },
                columnsClasses: {
                    Idemp: 'text-justify',
                    Stt: 'text-center',
                    Tipo: 'text-center',
                    Ciudad: 'text-center',
                    Agencia: 'text-center',
                    Verapp: 'text-center',
                    FU: 'text-center',
                    Fchcrt: 'text-center',
                    Rol: 'text-center',
                    Accion: 'text-center',
                    Usrentusr: 'text-justify',
                    Serial: 'text-justify',
                    Permisos: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Recopilando información, por favor espere...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            }
        }
    },
    mounted() {
        this.getData(this.typeUsers);
    },
    methods:{
        changePermisos(clave){
            this.$refs.addPermisos.idEmpleado = clave;
            this.$refs.addPermisos.getPermisos();
        },
        valideteRolAPK(tipo){
            var r = false;
            this.optionsTiposApp.forEach(function(value,key){
                //console.log(value+'-'+key+'-'+tipo);
                if(value.text === tipo){r = true;}
            });
            return r;
        },
        updateRol(usuario){let $this= this;
            var index = this.searchIndex(usuario);
            if (this.$refs['rol'+usuario].selectedValue!==null){
                axios.post('/view/updateRoleUser',{
                    rol:this.$refs['rol'+usuario].selectedValue,
                    user:this.tableData[index].Cveentusr
                })
                    .then(r => {
                            if (r.data.Success){
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    icon: 'success',
                                    title: r.data.Mensaje
                                });
                                $this.tableData[index].Rol=$this.$refs['rol'+usuario].selectedValue;
                                $this.tableData[index].RolUpdate=0;
                            }
                        },
                        error => {
                            console.error(error)
                        }
                    );
            }else{
                $this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    icon: 'error',
                    title: 'Por favor seleccione un nuevo Rol!'
                });
            }
        },
        searchIndex(value){
            var idn =0;
            for(var i = 0; i <this.tableData.length; i++) {
                if(this.tableData[i].Usrentusr === value) {
                    idn= i;
                }
            }
            return idn;
        },
        changeRol(usuario,type){
            var index = this.searchIndex(usuario);
            this.tableData[index].RolUpdate=type;
            this.tableData[index].Rol = this.tableData[index].RolAnterior;
        },
        saveTipo(clave){let $this = this;
            var indexSelected = this.$refs['opcionTipo'+clave].selectedIndex;
            var id = this.getIdx(clave);
            axios.post('/api/Users/changeTipo',{cveuser: clave, tipo: this.$refs['opcionTipo'+clave].value })
                .then(r => {
                        if (r.data.Success){
                            $this.tableData[id].Tipo = this.$refs['opcionTipo'+clave].options[indexSelected].text;
                            $this.tableData[id].Edit = false;
                            $this.$toastMessage('success',r.data.Mensaje,6000,'top-right')
                        }else{
                            $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        startEditTipo(clave){
            var id = this.getIdx(clave);
            this.optionsTipo=[];
            this.optionsTipo = this.$refs.newUserModal.optionsTipo;
            this.tableData[id].Edit = !this.tableData[id].Edit;
        },
        cancelEditTipo(clave){
            var id = this.getIdx(clave);
            this.tipoUserEdit={};
            this.tableData[id].Edit = false;
        },
        saveRol(clave){let $this = this;
            var indexSelected = this.$refs['opcionRol'+clave].selectedIndex;
            var id = this.getIdx(clave);
            axios.post('/api/Users/changeRol',{cveuser: clave, rol: this.$refs['opcionRol'+clave].value })
                .then(r => {
                        if (r.data.Success){
                            $this.tableData[id].Rol = this.$refs['opcionRol'+clave].options[indexSelected].text;
                            $this.tableData[id].EditRol = false;
                            $this.$toastMessage('success',r.data.Mensaje,6000,'top-right')
                        }else{
                            $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        startEditRol(clave){
            var id = this.getIdx(clave);
            this.tableData[id].EditRol = !this.tableData[id].EditRol;
        },
        cancelEditRol(clave){
            var id = this.getIdx(clave);
            this.rolUserEdit={};
            this.tableData[id].EditRol = false;
        },
         getData(type){let $this = this;
            this.typeUsers = type;this.showLoad=true;
             axios.post('/api/Users/getUsuarios',{typeUsers:type})
                 .then(r => {
                     if (r.data.Success){
                         $this.tableData = [];
                         $this.tableData = r.data.Data;
                         $this.showLoad=false;
                         $this.options.listColumns.Agencia = r.data.Agencias;
                         $this.options.listColumns.Tipo = r.data.Tipos;
                         $this.options.listColumns.Rol = r.data.Roles;
                         $this.optionsRol = r.data.Roles;
                         $this.optionsTiposApp = r.data.TiposApp;
                     }else{
                         $this.tableData = [];
                         $this.tableData = r.data.Data;
                         $this.showLoad=false;
                         $this.options.listColumns.Agencia = r.data.Agencias;
                         $this.options.listColumns.Tipo = r.data.Tipos;
                         $this.options.listColumns.Rol = r.data.Roles;
                         $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                     }
                 },
                 error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
             );
        },
        DeleteSerial(cveUser,Serial){let $this = this;
            if (Serial === ''){
                this.$toastMessage('error','No tiene serial que eliminar!',5000,'center');
            }else{
                var id = this.getIdx(cveUser);
                this.$swal.fire({
                    title: '¿Eliminar Serial?',
                    text: Serial,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, eliminar!',
                    cancelButtonText: 'No, cancelar!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('/api/Users/deleteSerial',{clave: cveUser})
                            .then(r => {
                                    if (r.data.Success){
                                        $this.tableData[id]['Serial'] = '';
                                        $this.$swal.fire(
                                            'Eliminado!',
                                            'Ahora puede ingresar con otro dispositivo.',
                                            'success'
                                        );
                                    }else{
                                        $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                    }
                                },
                                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                            );

                    }
                });
            }
        },
        getIdx(clave){let $this = this;
             var idx = 0;
             for (var i = 0; i< this.tableData.length; i++){
                 if (clave === $this.tableData[i]['Cveentusr']){
                     idx = i;
                 }
             }
             return idx;
        },
        DeleteAllSeriales(){let $this = this;
            this.$swal.fire({
                title: '¿Eliminar Todos Los Seriales?',
                text: "Esto solo se realiza en una actualización",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'No, cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api/Users/deleteAllSeriales')
                        .then(r => {
                                if (r.data.Success){
                                    $this.$swal.fire(
                                        'Eliminados!',
                                        'Ahora pueden ingresar con otro dispositivo.',
                                        'success'
                                    );
                                    $this.getData($this.typeUsers);
                                }else{
                                    $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                }
                            },
                            error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                        );
                }
            });
        },
        changePass(clave){let $this = this;
             var newPass = '';
            this.$swal.fire({
                title: 'Cambio de contraseña',
                icon: 'warning',
                input: 'text',
                inputLabel: 'Nueva Contraseña',
                inputValue: newPass,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'La contraseña no puede ser en blanco!'
                    }else if(value.length <=7){
                        return 'La contraseña no puede ser en menor a 7 caracteres!'
                    }else{
                        axios.post('/api/Users/changePassword',{clave: clave, pass: value})
                            .then(r => {
                                    if (r.data.Success){
                                        $this.$swal.fire(
                                            'Exito!',
                                            'Contraseña Actualizada.',
                                            'success'
                                        );
                                    }else{
                                        $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                    }
                                },
                                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                            );
                    }
                }
            });
        },
        ChangeStatus(clave,type){let $this = this;
            var id = this.getIdx(clave);
            this.$swal.fire({
                title: type === 0 ? '¿Desactivar Usuario?' : '¿Activar Usuario?',
                text: type === 0 ? 'Al aceptar, se le negará el acceso al usuario' : 'Al aceptar, se le permitirá el acceso al usuario',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: type === 0 ? 'Desactivar' : 'Activar',
                cancelButtonText: 'Cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api/Users/changeStatus',{clave: clave, stt: type})
                        .then(r => {
                                if (r.data.Success){
                                    $this.$swal.fire(
                                        'Exito!',
                                        'Acción realizada satisfactoriamente.',
                                        'success'
                                    );
                                    $this.tableData[id]['Stt'] = type === 0 ? 'Inactivo' : 'Activo';
                                }else{
                                    $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                }
                            },
                            error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                        );
                }
            });
        },
        ExportFile(){let $this = this;
            this.showLoadDownload = true;
            axios({
                method: 'post',
                url: '/api/Users/exportUsersToExcel',
                responseType: 'arraybuffer',
                data: {typeUsers: this.typeUsers}}).then(r => {
                        let blob = new Blob([r.data], { type: 'application/xlsx' })
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = 'UsuariosAtenea.xlsx'
                        link.click()
                        $this.showLoadDownload=false;
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        editUser(claveUser){
             var id = this.getIdx(claveUser);
             this.$refs.editUserModal.setData(this.tableData[id]);
             this.$refs.buttonModalEdit.click();
        },
        endEdit(){
             //console.log(this.$refs.editUserModal.dataEditUser);
             var id = this.getIdx(this.$refs.editUserModal.dataEditUser.cveusr);
             this.tableData[id].Nomentusr = this.$refs.editUserModal.dataEditUser.nombres;
             this.tableData[id].Apepat = this.$refs.editUserModal.dataEditUser.paterno;
             this.tableData[id].Apemat = this.$refs.editUserModal.dataEditUser.materno;
             this.tableData[id].Telefono = this.$refs.editUserModal.dataEditUser.tel;
             this.tableData[id].Usrentusr = this.$refs.editUserModal.dataEditUser.username;
             this.tableData[id].Cveentusr = this.$refs.editUserModal.dataEditUser.cveemp;
             this.tableData[id].Agencia = this.$refs.editUserModal.dataEditUser.agencia.title;
             this.tableData[id].CveCveAgencia = this.$refs.editUserModal.dataEditUser.agencia.value;
             this.tableData[id].Ciudad = this.$refs.editUserModal.dataEditUser.ciudad.title;
             this.tableData[id].CveCiudad = this.$refs.editUserModal.dataEditUser.ciudad.value;
             this.tableData[id].Nombre = this.$refs.editUserModal.dataEditUser.nombres + ' ' + this.$refs.editUserModal.dataEditUser.paterno + ' ' + this.$refs.editUserModal.dataEditUser.materno;

        }

    }
}
</script>

<style scoped>
tbody {
    display:block;
    height:400px;
    overflow:auto;
}

thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;/* even columns width , fix width of table too*/
}

thead {
    width: calc( 100% )/* scrollbar is average 1em/16px width, remove it from thead width */
}
</style>
