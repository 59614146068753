<template>
    <div></div>
</template>

<script>
export default {
    name: "EventsKeyUp",
    created() {
        const component = this;
        this.handler = function (e) {
            component.$emit('keyup', e);
        }
        window.addEventListener('keyup', this.handler);
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handler);
    }
}
</script>


