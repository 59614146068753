<template>
    <div>
        <v-select :multiple="true" :options="optionsFabricantes" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedFabricantes" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "fabricantes",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedFabricantes:[],
            optionsFabricantes:[]
        }
    },
    mounted() {
        this.getFabricantes();
    },
    methods:{
        getFabricantes(){
            let $this = this;
            axios.post('/api/AdminCatalogos/fabricantesSelectD')
                .then(r => {
                        $this.optionsFabricantes = r.data;
                        //console.log(r);
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        setCambio(){
            this.$emit('cambioFabricante');
        }
    }
}
</script>

<style scoped>

</style>
