import { render, staticRenderFns } from "./productos.vue?vue&type=template&id=d9e9fb9e&scoped=true&"
import script from "./productos.vue?vue&type=script&lang=js&"
export * from "./productos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9e9fb9e",
  null
  
)

export default component.exports