<template>
    <div>
        <v-select :multiple="true" :options="optionsAreas" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedAreas" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "areas",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedAreas:[],
            optionsAreas:[],
            agencia:[]
        }
    },
    mounted() {
        this.getAreas();
    },
    methods:{
        getAreas(){ let $this = this; this.selectedAreas = [];
            axios
                .post('/api/AdminCatalogos/areasSelect', {agencia: this.agencia})
                .then(function (r){
                    $this.optionsAreas = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioArea');
        }

    }
}
</script>

<style scoped>

</style>
