<template>
    <div>
        <v-select :multiple="true" :options="optionsCadenas" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedCadenas" @input="setCambio" :disabled="desa"></v-select>
    </div>
</template>

<script>
export default {
    name: "cadenas",
    props:{
        placeholderm:String,
        desa:Boolean,
    },
    data(){
        return{
            selectedCadenas:[],
            optionsCadenas:[],
            canal:[],
        }
    },
    mounted() {
        this.getCadenas();
    },
    methods:{
        getCadenas(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/cadenaSelectD',{canal:this.canal})
                .then(function (r){
                    $this.optionsCadenas = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioCadena');
        }

    }
}
</script>

<style scoped>

</style>
