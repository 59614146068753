import { render, staticRenderFns } from "./promotores.vue?vue&type=template&id=96ec07fc&scoped=true&"
import script from "./promotores.vue?vue&type=script&lang=js&"
export * from "./promotores.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96ec07fc",
  null
  
)

export default component.exports