<template>
    <div>
        <v-select :multiple="true" :options="optionsFormatos" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedFormatos" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "formatos",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedFormatos:[],
            optionsFormatos:[],
            cadenas:[],
        }
    },
    mounted() {
        this.getDataFormatos();
    },
    methods:{
        getDataFormatos(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/formatoSelectD',{cadenas: this.cadenas})
                .then(function (r){
                    $this.optionsFormatos = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioFormato');
        }
    }
}
</script>

<style scoped>

</style>
