<template>
    <div>
        <div class="row">
            <div class="col-md-4" v-if="false">
                <div class="row">
                    <div class="col-md-12">
                        <label>Fecha Inicio:</label>
                        <v-date-picker v-model='fechaInicio' color="orange" :attributes='attrs' @input="formattedDate(true)">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    class="bg-white border px-2 py-1 rounded form-control"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </div>
                    <div class="col-md-12">
                        <label>Fecha Termino:</label>
                        <v-date-picker v-model='fechaFin' color="orange" :attributes='attrs' @input="formattedDate(false)">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    class="bg-white border px-2 py-1 rounded form-control"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div>
                    <label><b>Imagen:</b></label><br>
                    <input type="file" name="myImage" accept="image/*"  class="form-control" @change="handleImage"/>
                </div><br>
                <i class="fa fa-eye" style="color: #0e9aef;cursor:pointer" @click="imgvisible = !imgvisible" v-show="!imgvisible"> Mostrar Imagen</i>
                <div v-show="dataForm.imagen!='' & imgvisible">
                    <i class="fa fa-eye" style="color: darkred;cursor:pointer" @click="imgvisible = !imgvisible"> Ocultar imagen</i>
                    <img :src="dataForm.imagen" class="img img-responsive" alt="ImagenPrioridad" />
                </div>
            </div>
            <div class="col-md-4">
                <br>
                <div class="custom-file">
                    <label for="logo" class="custom-file-label">ARCHIVO ENCUESTA:</label>
                    <input id="logo" type="file" class="form-control" ref="inputLoading"  @change="handleFile()" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                    <br>
                    <div class="text-center" v-if="loadFile">
                        <div class="spiner-example">
                            <div class="sk-spinner sk-spinner-rotating-plane"></div>
                        </div>
                    </div>
                    <button v-if="!loadFile" class="btn btn-success pull-right" @click="ProcesarArchivot"><i class="fa fa-cogs"></i> Procesar</button>
                </div>
            </div>
        </div>
        <br><br><br>
        <div class="row" v-if="aceptdFile">
            <div class="col-md-3">
                <div class="text-center">
                    <b>Productos Erroneos: {{productosError.length}}</b>
                    <p v-for="items in productosError">Fila: {{ items.Fila}} - {{items.Producto}}</p>
                </div>
            </div>
            <div class="col-md-3">
                <b>Tiendas Erroneas : {{tiendasError.length}}</b>
                <p v-for="items in tiendasError">Fila: {{ items.Fila}} - {{items.KCMID}}</p>
            </div>
            <div class="col-md-3">
                <div class="text-center">
                    <button class="btn btn-outline btn-danger dim" type="button" @click="cancelar()"><i class="fa fa-trash-o"></i> CANCELAR</button>
                </div>
            </div>
            <div class="col-md-3">
                <div class="text-center">
                    <button class="btn btn-outline btn-primary dim" type="button" @click="procesar()"><i class="fa fa-upload"></i> PROCESAR</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "loadEncuestas",
    data(){
        return {
            loadFile: false,
            fechaInicio: null,
            fechaFin: null,
            imgvisible: true,
            aceptdFile: false,
            file: '',
            attrs: [
                {
                    key: 'today',
                    highlight: true,
                    dates: new Date(),
                },
            ],
            dataForm: {
                fechaInicio: null,
                fechaFin: null,
                imagen: '',
                nomFile: ''
            },
            productosError: [],
            tiendasError: [],
        }

    },
    mounted(){

    },
    methods:{
        cancelar(){let $this = this;
            axios
                .post('/api/Encuestas/PeticionesNuevas',{tipo:2,file:this.dataForm.nomFile})
                .then(function(r){
                    $this.dataForm.imagen='';
                    $this.aceptdFile = false;
                    $this.imgvisible=false;
                });

        },
        procesar(){
            let $this = this;
            axios
                .post('/api/Encuestas/PeticionesNuevas',{tipo:3,file:this.dataForm.nomFile,imagen:this.dataForm.imagen})
                .then(function (r){
                    $this.dataForm.imagen='';
                    $this.aceptdFile = false;
                    $this.imgvisible=false;
                    $this.$toastMessage('success', 'Archivo cargado correctamente!',6000,'center');
                });

        },
        ProcesarArchivot(event)
        {
            if (this.file === '') {
                this.$toastMessage("error", "Seleccione el archivo a procesar por favor!",3000,'center');
            }
            else {
                let $this = this;
                this.loadFile = true;
                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('tipo', '1');
                formData.append('imagen', this.dataForm.imagen);
                axios.post('/api/Encuestas/PeticionesNuevas',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function (r) {
                    console.log(r);
                    if (r.data.Success) {
                        if (r.data.TipoRespuestaV === '') {
                            $this.$toastMessage('error', 'Tipo de respuesta invalida',3000,'center');
                        } else if (r.data.PromocionV === '') {
                            $this.$toastMessage('error', 'Tipo de promoción invalida',3000,'center');
                        } else if (r.data.CategoriaV === '') {
                            $this.$toastMessage('error', 'Categoria invalida',3000,'center');
                        } else {
                            $this.dataForm.nomFile = r.data.File;
                            $this.productosError = r.data.ProductosF;
                            $this.tiendasError = r.data.TiendasFalla;
                            $this.$toastMessage('success', 'Se muestra resumen',3000,'center');
                        }

                    }
                    else {
                        $this.$toastMessage('error', 'Valide que se trata de un layout correcto! puede mandar este archivo a soporte Técnico',3000,'center');
                    }
                    console.log(r.data);
                    $this.loadFile = false;
                    $this.aceptdFile = true;
                    $this.file = '';
                    $this.$refs.inputLoading.value = null;
                })
                    .catch(function (e) {
                        console.log('FAILURE!! ' + e);
                        $this.$refs.inputLoading.value = null;
                    });
            }
        },
        handleFile(){
            if (this.$refs.inputLoading.files.length>0){
                this.file = this.$refs.inputLoading.files[0];
            }
            else{
                this.file = '';
            }
        },
        formattedDate(i) {
            if (i){
                var mm = this.fechaInicio.getMonth() + 1; // getMonth() is zero-based
                var dd = this.fechaInicio.getDate();
                var fecha = [this.fechaInicio.getFullYear(),
                    (mm>9 ? '' : '0') + mm,
                    (dd>9 ? '' : '0') + dd
                ].join('-');
                this.dataForm.fechaInicio = fecha;
            }else{
                var mm = this.fechaFin.getMonth() + 1; // getMonth() is zero-based
                var dd = this.fechaFin.getDate();
                var fecha = [this.fechaFin.getFullYear(),
                    (mm>9 ? '' : '0') + mm,
                    (dd>9 ? '' : '0') + dd
                ].join('-');
                this.dataForm.fechaFin = fecha;
            }
        },
        handleImage(e){let $this = this;
            const selectedImage = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function(event) {
                $this.dataForm.imagen = event.target.result;
            };
            reader.onerror = function(error) {
                alert(error);
            };
            reader.readAsDataURL(selectedImage);
        },
    }
}
</script>

<style scoped>

</style>
