<template>
    <div>
        <v-select :multiple="true" :options="optionsCategorias" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedCategorias" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "categorias",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedCategorias:[],
            optionsCategorias:[
                {"value":11,"label":"TODAS",},
                {"value":12,"label":"HIGIÉNICOS",},
                {"value":13,"label":"PAÑALES",},
                {"value":14,"label":"JABONES",}
            ],
            departamentos:[]
        }
    },
    mounted() {
        //this.getCategorias();
    },
    methods:{
        getCategorias(){
            let $this = this;
            axios.post('/api/AdminCatalogos/categoriasSelectD',{departamentos:this.departamentos})
                .then(r => {
                        $this.optionsCategorias = r.data;
                        //console.log(r);
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        setCambio(){
            this.$emit('cambioCategoria');
        }
    }
}
</script>

<style scoped>

</style>
