<template>
    <div>
        <v-select :multiple="false" :options="optionsEncuestas" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedEncuestas" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "encuestasFiltro",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedEncuestas:null,
            optionsEncuestas:[],
            tipoConsulta:true
        }
    },
    mounted() {
        this.getEncuestas();
    },
    methods:{
        getEncuestas(){ let $this = this;
            this.selectTodosProductos = null;
            axios
                .post('/api/AdminCatalogos/getEncuestasFiltro',{tipo:this.tipoConsulta})
                .then(function (r){
                    $this.optionsEncuestas = r.data;
                    $this.$emit('cargaEncuesta');
                });
        },
        setCambio(){
            this.$emit('cambioEncuesta');
        }
    }
}
</script>

<style scoped>

</style>
