<template>
    <div>
        <v-select :multiple="true" :options="optionsCiudades" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedCiudad" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "ciudades",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedCiudad:[],
            optionsCiudades:[],
            estados:[]
        }
    },
    mounted() {
        this.getCiudades();
    },
    methods:{
        getCiudades(){ let $this = this; this.selectedCiudad = [];
            axios
                .post('/api/AdminCatalogos/ciudadesSelect', {estados: this.estados})
                .then(function (r){
                    $this.optionsCiudades = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioCiudad');
        }

    }
}
</script>

<style scoped>

</style>
