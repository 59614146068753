<template>
    <div>
        <!-- Modal effects -->
        <div class="modal" id="modalnewUser" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Nuevo Usuario <i class="fa fa-user-plus"></i></h6>
                        <button ref="closeModal" class="btn ripple btn-secondary text-right btn-with-icon" data-dismiss="modal" type="button">Cancelar &nbsp;&nbsp;<i class="fa fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                        <form-wizard @on-complete="onComplete"
                                     @on-loading="setLoading"
                                     @on-validate="handleValidation"
                                     @on-error="handleErrorMessage" ref="formNewUser"
                                     shape="circle" back-button-text="Anterior" next-button-text="Siguiente" finish-button-text="Registrar"
                                     color="#036296" title="" subtitle="" :start-index="0"
                                     error-color="#e74c3c">
                            <tab-content title="Datos Nuevo Usuario"
                                         :before-change="validateDataUser"
                                         icon="fa fa-user">
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="mg-b-10">Usuario</p>
                                        <input type="text" class="form-control" :class="{ 'is-invalid state-invalid': $v.dataNewUser.username.$invalid }" v-model="dataNewUser.username"  placeholder="Ingresa usuario">
                                        <div class="invalid-feedback">{{!$v.dataNewUser.username.required ? 'Ingrese el usuario' : !$v.dataNewUser.username.minLength ? 'Longitud minima ' + $v.dataNewUser.username.$params.minLength.min : 'error'}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="mg-b-10">Número de empleado</p>
                                        <input type="text" class="form-control" :class="{ 'is-invalid state-invalid': $v.dataNewUser.cveemp.$invalid }" v-model="dataNewUser.cveemp"  placeholder="Ingresa número empleado" @keypress="isNumber($event)">
                                        <div class="invalid-feedback">{{!$v.dataNewUser.cveemp.required ? 'Ingrese número de empleado' : !$v.dataNewUser.cveemp.minLength ? 'Longitud minima ' + $v.dataNewUser.cveemp.$params.minLength.min : 'error'}}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="mg-b-10">Contraseña</p>
                                        <input type="text" class="form-control" :class="{ 'is-invalid state-invalid': $v.dataNewUser.password.$invalid }" v-model="dataNewUser.password"  placeholder="Ingresa contraseña">
                                        <div class="invalid-feedback">{{!$v.dataNewUser.password.required ? 'Ingrese contraseña' : !$v.dataNewUser.password.minLength ? 'Longitud minima ' + $v.dataNewUser.password.$params.minLength.min : 'error'}}</div>
                                    </div>
                                </div>
                                <br><br>
                            </tab-content>
                            <tab-content title="Datos personales"
                                         :before-change="validateDataPersonal"
                                         icon="fa fa-book">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p class="mg-b-10">Nombre(s)</p>
                                        <input type="text" class="form-control" :class="{ 'is-invalid state-invalid': $v.dataNewUser.nombres.$invalid }" v-model="dataNewUser.nombres"  placeholder="Ingresa nombre(s)">
                                        <div class="invalid-feedback">{{!$v.dataNewUser.nombres.required ? 'Ingrese nombre(s)' : !$v.dataNewUser.nombres.minLength ? 'Longitud minima ' + $v.dataNewUser.nombres.$params.minLength.min : 'error'}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="mg-b-10">Apellido Paterno</p>
                                        <input type="text" class="form-control" :class="{ 'is-invalid state-invalid': $v.dataNewUser.paterno.$invalid }" v-model="dataNewUser.paterno"  placeholder="Ingresa apellido paterno">
                                        <div class="invalid-feedback">{{!$v.dataNewUser.paterno.required ? 'Ingrese apellido paterno' : !$v.dataNewUser.paterno.minLength ? 'Longitud minima ' + $v.dataNewUser.paterno.$params.minLength.min : 'error'}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p class="mg-b-10">Apellido Materno</p>
                                        <input type="text" class="form-control" :class="{ 'is-invalid state-invalid': $v.dataNewUser.materno.$invalid }" v-model="dataNewUser.materno"  placeholder="Ingresa apellido materno">
                                        <div class="invalid-feedback">{{!$v.dataNewUser.materno.required ? 'Ingrese apellido materno' : !$v.dataNewUser.materno.minLength ? 'Longitud minima ' + $v.dataNewUser.materno.$params.minLength.min : 'error'}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="mg-b-10">Teléfono</p>
                                        <input type="text" class="form-control" :class="{ 'is-invalid state-invalid': $v.dataNewUser.tel.$invalid }" v-model="dataNewUser.tel"  placeholder="Ingresa número empleado" @keypress="isNumber($event)" maxlength="10">
                                        <div class="invalid-feedback">{{!$v.dataNewUser.tel.minLength ? 'Longitud minima ' + $v.dataNewUser.tel.$params.minLength.min : 'error'}}</div>
                                    </div>
                                </div>
                                <br><br>
                            </tab-content>
                            <tab-content title="Datos Empresa" :before-change="validateDataEmpresa"
                                         icon="fa fa-cogs">
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-4">
                                        <p class="mg-b-10">Tipo</p>
                                        <multiselect v-model="dataNewUser.tipo" :options="optionsTipo" select-label="Click para seleccionar"
                                                     deselect-label="No se puede quitar" placeholder="Seleccione..." :close-on-select="true" :allow-empty="false"
                                                     :clear-on-select="true"  :class="{ 'is-danger': !Object.keys(dataNewUser.tipo).length }" track-by="title" :custom-label="title">
                                            <template slot="singleLabel" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                            <template slot="option" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="mg-b-10">Empresa</p>
                                        <multiselect v-model="dataNewUser.agencia" :options="optionsAgencias" select-label="Click para seleccionar" :allow-empty="false"
                                                     deselect-label="No se puede quitar" placeholder="Seleccione..." :close-on-select="true" :custom-label="title"
                                                     :clear-on-select="true" :class="{ 'is-danger': !Object.keys(dataNewUser.agencia).length }" track-by="title">
                                            <template slot="singleLabel" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                            <template slot="option" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div><br>
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-3">
                                        <p class="mg-b-10">Estatus</p>
                                        <multiselect v-model="dataNewUser.estatus" :options="optionsEstatus" select-label="Click para seleccionar"
                                                     deselect-label="No se puede quitar" placeholder="Seleccione..." :close-on-select="true" :custom-label="title" :allow-empty="false"
                                                     :clear-on-select="true" :preselect-first="true" :class="{ 'is-danger': !Object.keys(dataNewUser.estatus).length }" track-by="title">
                                            <template slot="singleLabel" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                            <template slot="option" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="col-md-5">
                                        <p class="mg-b-10">Ciudad</p>
                                        <multiselect v-model="dataNewUser.ciudad" :options="optionsCiudades" select-label="Click para seleccionar"
                                                     deselect-label="No se puede quitar" placeholder="Seleccione..." :close-on-select="true" :allow-empty="false"
                                                     :class="{ 'is-danger': !Object.keys(dataNewUser.ciudad).length }" track-by="title" :custom-label="title">
                                            <template slot="singleLabel" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                            <template slot="option" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                                <br><br>
                            </tab-content>

                            <div class="row" v-if="loadingWizard">
                                <div class="col-md-12 text-center">
                                    <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                                    <p><b>Validando información por favor espere!</b></p>
                                </div>
                            </div>
                            <div class="row" v-if="errorMsg">
                                <div class="col-md-12 text-center"><br><br>
                                    <span class="text-danger">{{errorMsg}}</span>
                                </div>
                            </div>
                        </form-wizard>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal effects-->
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

export default {
    name: "newUser",
    data(){
        return{
            loadingWizard: false,
            errorMsg: null,
            dataNewUser:{
                username: '',
                cveemp: '',
                password:'',
                nombres:'',
                paterno:'',
                materno:'',
                tel:'',
                estatus:{},
                ciudad: {},
                tipo:{},
                agencia:{},
            },
            value: null,
            options: ['list', 'of', 'options'],
            optionsEstatus: [{title:'Activo', value:1},{title:'Inactivo',value:0}],
            optionsTipo: [],
            optionsCiudades: [],
            optionsAgencias: [],
        }
    },
    validations: {
        dataNewUser: {
            username: {
                required, minLength: minLength(5)
            },
            password: {
                required, minLength: minLength(8)
            },
            cveemp: {
                required, minLength: minLength(3)
            },
            nombres: {
                required, minLength: minLength(4)
            },
            paterno: {
                required, minLength: minLength(4)
            },
            materno: {
                required, minLength: minLength(4)
            },
            tel: {
                minLength: minLength(10)
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData(){let $this = this;
            axios.post('/api/AdminCatalogos/getOptionsNewUsuarios')
                .then(r => {
                        if (r.data.Success){
                            $this.optionsCiudades = r.data.Ciudades;
                            $this.optionsTipo = r.data.Tipo;
                            $this.optionsAgencias = r.data.Agencias;
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        onComplete(){let $this = this;
            axios.post('/api/Users/saveNewUser',{dataUser: this.dataNewUser})
                .then(r => {
                        if (r.data.Success){
                            $this.dataNewUser.username='';
                            $this.dataNewUser.cveemp='';
                            $this.dataNewUser.password='';
                            $this.dataNewUser.nombres='';
                            $this.dataNewUser.paterno='';
                            $this.dataNewUser.materno='';
                            $this.dataNewUser.tel='';
                            $this.dataNewUser.tipo={};
                            $this.dataNewUser.agencia={};
                            $this.dataNewUser.estatus={};
                            $this.dataNewUser.ciudad={};
                            $this.$refs.closeModal.click();
                            $this.$refs.formNewUser.reset();
                            $this.$emit('myEvent');
                            $this.$toastMessage('success', r.data.Mensaje,6000,'top-right');
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        setLoading(value){
            this.loadingWizard = value
        },
        handleValidation(isValid, tabIndex){
            console.log('Tab: '+tabIndex+ ' valid: '+isValid)
        },
        handleErrorMessage(errorMsg){
            this.errorMsg = errorMsg
        },
        validateDataUser() {let $this = this;
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if(this.$v.dataNewUser.username.$invalid){
                        reject('Ingrese un usuario valido!');
                    }else if(this.$v.dataNewUser.cveemp.$invalid){
                        reject('Ingrese una clave valida!');
                    }else if(this.$v.dataNewUser.password.$invalid){
                        reject('Ingrese una contraseña valida!');
                    }
                    else{
                        axios.post('/api/Users/validateUserName',{username: this.dataNewUser.username})
                            .then(r => {
                                    if (r.data.Success){
                                        resolve(true);
                                    }else{
                                        $this.$toastMessage('error','Error: Usuario ya existe, cambie el nombre de usuario!',6000,'top-right');
                                        reject('Error: Usuario ya existe, cambie el nombre de usuario!');
                                    }
                                },
                                error => {
                                    reject('Error: ' + error +' Por favor envia este error al área de soporte!');
                                $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                            }
                            );
                    }
                }, 1000);
            })
        },
        validateDataPersonal() {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if(this.$v.dataNewUser.nombres.$invalid){
                        reject('Ingrese un nombre valido!');
                    }else if(this.$v.dataNewUser.paterno.$invalid){
                        reject('Ingrese un apellido paterno valido!');
                    }
                    else if(this.$v.dataNewUser.materno.$invalid){
                        reject('Ingrese un apellido materno valido!');
                    }else if(this.$v.dataNewUser.tel.$invalid){
                        reject('Ingrese un número de celular valido!');
                    }
                    else{
                        resolve(true)
                    }
                }, 1000)
            });
        },
        validateDataEmpresa(){
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if(!Object.keys(this.dataNewUser.tipo).length){
                        reject('Seleccione un tipo valido!');
                    }else if(!Object.keys(this.dataNewUser.agencia).length){
                        reject('Seleccione una empresa valida!');
                    }
                    else if(!Object.keys(this.dataNewUser.estatus).length){
                        reject('Seleccione un estatus valido!');
                    }else if(!Object.keys(this.dataNewUser.ciudad).length){
                        reject('Seleccione una ciudad valida!');
                    }
                    else{
                        resolve(true)
                    }
                }, 1000);
            });
        },
       title ({ title }) {
            return `${title}`
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    }
}
</script>

<style>
.vue-form-wizard .wizard-nav-pills > li > a {
    color: #d0d63a;
}
.multiselect__option--highlight{
    background: #d0d63a !important;
}

.is-danger .multiselect__tags {
    border-color: red;
}
</style>
