<template>
    <div>
        <v-select v-model="selectedValue" :options="valueRoles"  ></v-select>
    </div>
</template>

<script>
    export default {
        name: "chosenRolSupervisor",
        data(){
            return{
                selectedValue:null,
                valueRoles:[],
            }
        },
        mounted() {let $this=this;
            axios.post('/view/getRoles',{})
                .then(r => {
                        $this.valueRoles = r.data.Roltpousrs;
                    },
                    error => {
                        console.error(error)
                    }
                );
        },
        methods:{

        }
    }
</script>

<style scoped>

</style>
