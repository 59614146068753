<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <div class="text-center">
                    <div class="row">
                        <div class="col-md-10">
                            <h3>CANALES</h3>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-success dim text-center" type="button" @click="newRegister('CANAL')" data-effect="effect-just-me" data-toggle="modal" href="#myModal5"><i class="fa fa-plus-circle"></i></button>
                        </div>
                    </div>
                </div><br><br>
                <v-client-table :data="tableDataCanal" :columns="columnsCanal" :options="optionsCanal" class="table-responsive">
                    <div slot="Canvta" slot-scope="props">
                        {{props.row.Canvta}}<br>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <i class="fas fa-edit" style="color:blue;cursor:pointer;" v-if="props.row.EditCanal === '0'" @click="editNombreCanal(props.row.Cveentcan)"></i>
                                <i class="fas fa-times" style="color:red;cursor:pointer;" v-if="props.row.EditCanal === '1'" @click="cancelNombreCanal(props.row.Cveentcan)"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                                <i class="fas fa-save" style="color:green;cursor:pointer;" v-if="props.row.EditCanal === '1'" @click="saveNombreCanal(props.row.Cveentcan)"></i>
                            </div>
                        </div>
                        <div class="row" v-if="props.row.EditCanal === '1'">
                            <div class="col-md-12">
                                <textarea maxlength="120" placeholder="Nombre Canal" v-model="newNombreCanal" class="form-control" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </v-client-table>
            </div>
            <div class="col-md-4">
                <div class="text-center">
                    <div class="row">
                        <div class="col-md-10">
                            <h3>CADENAS</h3>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-success dim text-center" type="button" @click="newRegister('CADENA')" data-effect="effect-just-me" data-toggle="modal" href="#myModal5"><i class="fa fa-plus-circle"></i></button>
                        </div>
                    </div>
                </div><br><br>
                <v-client-table :data="tableDataCadenas" :columns="columnsCadenas" :options="optionsCadenas" class="table-responsive">
                    <div slot="Nomentcad" slot-scope="props">
                        {{props.row.Nomentcad}}<br>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <i class="fas fa-edit" style="color:blue;cursor:pointer;" v-if="props.row.EditCadena === '0'" @click="editNombreCadena(props.row.Cveentcad)"></i>
                                <i class="fas fa-times" style="color:red;cursor:pointer;" v-if="props.row.EditCadena === '1'" @click="cancelNombreCadena(props.row.Cveentcad)"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                                <i class="fas fa-save" style="color:green;cursor:pointer;" v-if="props.row.EditCadena === '1'" @click="saveNombreCadena(props.row.Cveentcad)"></i>
                            </div>
                        </div>
                        <div class="row" v-if="props.row.EditCadena === '1'">
                            <div class="col-md-12">
                                <textarea maxlength="120" placeholder="Nombre Cadena" v-model="newNombreCadena" class="form-control" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </v-client-table>
            </div>
            <div class="col-md-5">
                <div class="text-center">
                    <div class="row">
                        <div class="col-md-10">
                            <h3>FORMATOS</h3>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-success dim text-center" type="button" @click="newRegister('FORMATO')" data-effect="effect-just-me" data-toggle="modal" href="#myModal5"><i class="fa fa-plus-circle"></i></button>
                        </div>
                    </div>
                </div><br><br>
                <v-client-table :data="tableDataFormatos" :columns="columnsFormatos" :options="optionsFormatos" class="table-responsive">
                    <div slot="Nomentfmt" slot-scope="props">
                        {{props.row.Nomentfmt}}<br>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <i class="fas fa-edit" style="color:blue;cursor:pointer;" v-if="props.row.EditFormato === '0'" @click="editNombreFormato(props.row.Cveentfmt)"></i>
                                <i class="fas fa-times" style="color:red;cursor:pointer;" v-if="props.row.EditFormato === '1'" @click="cancelNombreFormato(props.row.Cveentfmt)"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                                <i class="fas fa-save" style="color:green;cursor:pointer;" v-if="props.row.EditFormato === '1'" @click="saveNombreFormato(props.row.Cveentfmt)"></i>
                            </div>
                        </div>
                        <div class="row" v-if="props.row.EditFormato === '1'">
                            <div class="col-md-12">
                                <textarea maxlength="120" placeholder="Nombre Cadena" v-model="newNombreFormato" class="form-control" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </v-client-table>
            </div>
        </div>
        <div class="modal inmodal" :class="{'show':shoModal}" id="myModal5" tabindex="-1" role="dialog"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <button id="close" type="button" class="close" @click="shoModal = !shoModal" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
                        <i class="fa fa-bookmark-o modal-icon"></i>
                        <h4 class="modal-title">Nuevo {{typeNew}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6" v-show="typeNew==='CANAL'">
                                Canal:<br>
                                <input type="text" class="form-control" v-model="newCanal" placeholder="Ingrese nuevo canal">
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                        <div class="row" v-show="typeNew==='CADENA'">
                            <div class="col-md-6">
                                Canal:<br>
                                <v-select :multiple="false" :options="optionsCanales" :placeholder="'Selecciona Canal'" v-model="selectedCanales"></v-select>
                            </div>
                            <div class="col-md-6">
                                Cadena:<br>
                                <input type="text" class="form-control" v-model="newCadena" placeholder="Ingrese nueva cadena">
                            </div>
                        </div>
                        <div class="row" v-show="typeNew==='FORMATO'">
                            <div class="col-md-6">
                                Cadena:<br>
                                <v-select :multiple="false" :options="optionsCadenasS" :placeholder="'Selecciona Cadena'" v-model="selectedCadenas"></v-select>
                            </div>
                            <div class="col-md-6">
                                Formato:<br>
                                <input type="text" class="form-control" v-model="newFormato" placeholder="Ingrese nuevo formato">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" @click="saveCambio">Registrar <i class="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "canalcadenaformato",
    data(){
        return{
            newNombreCanal:'',
            edtNombreCanal:false,
            newNombreCadena:'',
            edtNombreCadena:false,
            newNombreFormato:'',
            edtNombreFormato:false,
            columnsCanal: ['Cveentcan','Canvta'],
            tableDataCanal: [],
            optionsCanal: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10,15,25,45,50],
                filterable: ['Canvta'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Cveentcan: '#',
                    Canvta: 'Canal',
                },
                columnsClasses: {
                    Cveentcan: 'text-center font-bold text-danger',
                    Canal: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsCadenas: ['Cveentcad','Canal','Nomentcad'],
            tableDataCadenas: [],
            optionsCadenas: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10,15,25,45,50],
                filterable: ['Nomentcad','Canal'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Cveentcad: '#',
                    Nomentcad: 'Cadena',
                },
                columnsClasses: {
                    Cveentcad: 'text-center font-bold text-danger',
                    Canal: 'text-justify',
                    Nomentcad: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsFormatos: ['Cveentfmt','Canal','Cadena','Nomentfmt','Total'],
            tableDataFormatos: [],
            optionsFormatos: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10,15,25,45,50],
                filterable: ['Canal','Cadena','Nomentfmt'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Cveentfmt: '#',
                    Nomentfmt: 'Formato',
                    Total: 'Total Tiendas',
                },
                columnsClasses: {
                    Cveentfmt: 'text-center font-bold text-danger',
                    Total: 'text-center font-bold text-primary',
                    Canal: 'text-justify',
                    Nomentfmt: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            shoModal:false,
            typeNew:'',
            optionsCanales:[],
            selectedCanales: {value:1, label:''},
            optionsCadenasS:[],
            selectedCadenas: {value:1, label:''},
            newCanal: '',
            newCadena: '',
            newFormato: '',
        };
    },
    mounted(){
        this.getCanales();
        this.getCadenas();
        this.getFormatos();
        this.getCanalesSelect();
        this.getCadenasSelect();
    },
    methods:{
        editNombreCanal(Id){let $this = this;
            if (this.edtNombreCanal){
                $this.$toastMessage("error","Edición de nombre de canal en proceso! termine el proceso para editar otro nombre de canal.",3000,'top-center');
            }
            else{
                this.edtNombreCanal=true;
                var idx = $this.findIdxCanal(Id);
                this.newNombreCanal = $this.tableDataCanal[idx].Canvta;
                $this.tableDataCanal[idx].EditCanal = '1';
            }
        },
        cancelNombreCanal(Id){let $this = this;
            this.edtNombreCanal=false;
            this.newNombreCanal ='';
            var idx = $this.findIdxCanal(Id);
            $this.tableDataCanal[idx].EditCanal = '0';
        },
        saveNombreCanal(Id){ let $this = this;
            $this.$toastMessage("info","Realizando la petición seleccionada.",3000,'top-right');
            var idx = $this.findIdxCanal(Id);
            setTimeout(function(){
                axios.post('/api/Tiendas/peticiones',{solicitud:21,canal:Id, nombre:$this.newNombreCanal})
                    .then(function (r){
                        $this.tableDataCanal[idx].EditCanal = '0';
                        $this.tableDataCanal[idx].Canvta = $this.newNombreCanal;
                        $this.newNombreCanal ='';
                        $this.edtNombreCanal=false;
                        $this.$toastMessage("success","Canal actualizado correctamente.",3000,'top-right');
                    },error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    });

            }, 1500);
        },
        editNombreCadena(Id){let $this = this;
            if (this.edtNombreCadena){
                $this.$toastMessage("error","Edición de nombre de cadena en proceso! termine el proceso para editar otro nombre de cadena.",3000,'top-center');
            }
            else{
                this.edtNombreCadena=true;
                var idx = $this.findIdxCadena(Id);
                this.newNombreCadena = $this.tableDataCadenas[idx].Nomentcad;
                $this.tableDataCadenas[idx].EditCadena = '1';
            }
        },
        cancelNombreCadena(Id){let $this = this;
            this.edtNombreCadena=false;
            this.newNombreCadena ='';
            var idx = $this.findIdxCadena(Id);
            $this.tableDataCadenas[idx].EditCadena = '0';
        },
        saveNombreCadena(Id){ let $this = this;
            $this.$toastMessage("info","Realizando la petición seleccionada.",3000,'top-right');
            var idx = $this.findIdxCadena(Id);
            setTimeout(function(){
                axios.post('/api/Tiendas/peticiones',{solicitud:22,cadena:Id, nombre:$this.newNombreCadena})
                    .then(function (r){
                        $this.tableDataCadenas[idx].EditCadena = '0';
                        $this.tableDataCadenas[idx].Nomentcad = $this.newNombreCadena;
                        $this.newNombreCadena ='';
                        $this.edtNombreCadena=false;
                        $this.$toastMessage("success","Canal actualizada correctamente.",3000,'top-right');
                    },error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    });

            }, 1500);
        },
        editNombreFormato(Id){let $this = this;
            if (this.edtNombreFormato){
                $this.$toastMessage("error","Edición de nombre de formato en proceso! termine el proceso para editar otro nombre de formato.",3000,'top-center');
            }
            else{
                this.edtNombreFormato=true;
                var idx = $this.findIdxFormato(Id);
                this.newNombreFormato = $this.tableDataFormatos[idx].Nomentfmt;
                $this.tableDataFormatos[idx].EditFormato = '1';
            }
        },
        cancelNombreFormato(Id){let $this = this;
            this.edtNombreFormato=false;
            this.newNombreFormato ='';
            var idx = $this.findIdxFormato(Id);
            $this.tableDataFormatos[idx].EditFormato = '0';
        },
        saveNombreFormato(Id){ let $this = this;
            $this.$toastMessage("info","Realizando la petición seleccionada.",3000,'top-right');
            var idx = $this.findIdxFormato(Id);
            setTimeout(function(){
                axios.post('/api/Tiendas/peticiones',{solicitud:23,formato:Id, nombre:$this.newNombreFormato})
                    .then(function (r){
                        $this.tableDataFormatos[idx].EditFormato = '0';
                        $this.tableDataFormatos[idx].Nomentfmt = $this.newNombreFormato;
                        $this.newNombreFormato ='';
                        $this.edtNombreFormato=false;
                        $this.$toastMessage("success","Formato actualizado correctamente.",3000,'top-right');
                    },error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    });

            }, 1500);
        },
        saveCambio(){ let $this = this;
            if (this.typeNew === 'CANAL'){
                if (this.newCanal === '' || this.newCanal.length<3){
                    $this.$toastMessage('error','Ingrese un texto o el texto mayor a 3 caracteres!',3000,'top-right');
                }
                else{
                    axios
                        .post('/api/Tiendas/peticiones',{solicitud:10, newCanal: this.newCanal})
                        .then(function (r){
                            if (r.data.Success){
                                $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                                $this.shoModal = !$this.shoModal;
                                document.getElementById('close').click();
                                $this.newCanal = '';
                                $this.getCanalesSelect();
                                $this.getCanales();
                            }
                            else{
                                $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                            }

                        });

                }
            }
            if (this.typeNew === 'CADENA'){
                if (this.newCadena === '' || this.newCadena.length<3){
                    $this.$toastMessage('error','Ingrese un texto o el texto mayor a 3 caracteres!',3000,'top-right');
                }
                else{
                    if (this.selectedCanales === [] || this.selectedCanales.label === ''){
                        $this.$toastMessage('error','Seleccione el canal para registrar una nueva cadena!',3000,'top-right');
                    }
                    else{
                        axios
                            .post('/api/Tiendas/peticiones',{solicitud:11, newCadena: this.newCadena, canal:this.selectedCanales.value})
                            .then(function (r){
                                if (r.data.Success){
                                    $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                                    $this.shoModal = !$this.shoModal;
                                    $this.newCadena = '';
                                    $this.selectedCanales = [];
                                    $this.getCadenasSelect();
                                    $this.getCadenas();
                                    document.getElementById('close').click();
                                }
                                else{
                                    $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                                }

                            });
                    }

                }
            }
            if (this.typeNew === 'FORMATO'){
                if (this.newFormato === '' || this.newFormato.length<3){
                    $this.$toastMessage('error','Ingrese un texto o el texto mayor a 3 caracteres!',3000,'top-right');
                }
                else{
                    if (this.selectedCadenas === [] || this.selectedCadenas.label === ''){
                        $this.$toastMessage('error','Seleccione el cadena para registrar un nuevo formato!',3000,'top-right');
                    }
                    else{
                        axios
                            .post('/api/Tiendas/peticiones',{solicitud:12, newFormato: this.newFormato, cadena:this.selectedCadenas.value})
                            .then(function (r){
                                if (r.data.Success){
                                    $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                                    $this.shoModal = !$this.shoModal;
                                    $this.newFormato = '';
                                    $this.selectedCadenas = [];
                                    $this.getFormatos();
                                    document.getElementById('close').click();
                                }
                                else{
                                    $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                                }

                            });
                    }

                }
            }

        },
        newRegister(typeR){
            this.typeNew = typeR;
            this.shoModal = !this.shoModal;
        },
        getCanales(){ let $this = this;
            axios
                .post('/api/Tiendas/peticiones',{solicitud:7})
                .then(function (r){
                    $this.tableDataCanal = r.data;
                });
        },
        getCadenas(){ let $this = this;
            axios
                .post('/api/Tiendas/peticiones',{solicitud:8})
                .then(function (r){
                    $this.tableDataCadenas = r.data;
                });
        },
        getCanalesSelect(){ let $this = this;
            axios
                .post('/api/Tiendas/peticiones',{solicitud:4})
                .then(function (r){
                    $this.optionsCanales = r.data;
                });
        },
        getCadenasSelect(){let $this = this;
            axios
                .post('/api/Tiendas/peticiones',{solicitud:5,canal:'0'})
                .then(function (r){
                    $this.optionsCadenasS = r.data;
                });
        },
        getFormatos(){ let $this = this;
            axios
                .post('/api/Tiendas/peticiones',{solicitud:9})
                .then(function (r){
                    $this.tableDataFormatos = r.data;
                });
        },
        findIdxCanal(findId){let $this = this;
            var idn = 0;
            var a = 0;
            for(a;a<$this.tableDataCanal.length;a++){
                if ($this.tableDataCanal[a].Cveentcan === findId){
                    idn = a;
                }
            }
            return idn;
        },
        findIdxCadena(findId){let $this = this;
            var idn = 0;
            var a = 0;
            for(a;a<$this.tableDataCadenas.length;a++){
                if ($this.tableDataCadenas[a].Cveentcad === findId){
                    idn = a;
                }
            }
            return idn;
        },
        findIdxFormato(findId){let $this = this;
            var idn = 0;
            var a = 0;
            for(a;a<$this.tableDataFormatos.length;a++){
                if ($this.tableDataFormatos[a].Cveentfmt=== findId){
                    idn = a;
                }
            }
            return idn;
        },
    }
}
</script>

<style scoped>

</style>
