<template>
    <div>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-center">
                            <button v-if="!edit" class="btn btn-success  dim" type="button" @click="edit=!edit"><i class="fa fa-plus"></i> AGREGAR CIUDAD</button>
                            <button v-if="edit" class="btn btn-primary  dim" type="button" @click="guardarCiudad()"><i class="fa fa-save"></i> GUARDAR</button>
                            <button v-if="edit" class="btn btn-danger  dim" type="button" @click="edit=!edit;ciudad=''"><i class="fa fa-trash-o"></i> CANCELAR</button>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="edit">
                    <div class="col-md-6">
                        <select class="form-control" v-model="estado">
                            <option value="0" selected>Seleccione Estado</option>
                            <option v-for="items in optionsCiudades.listColumns.Estado" :value="items.clave">{{items.text}}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <input type="text" maxlength="30" placeholder="Ingrese nombre de la ciudad" class="form-control" v-model="ciudad">
                    </div>
                </div>
                <br><br>
                <v-client-table :data="tableDataCiudades" :columns="columnsCiudades" :options="optionsCiudades" class="table-responsive">
                    <div slot="Nomentciu" slot-scope="props">
                        {{props.row.Nomentciu}} -> <b class="tx-primary">{{props.row.Total}}</b>
                        <span class="pull-right" v-if="props.row.Total === 0">
                            <i class="fas fa-eraser" style="color:red;cursor:pointer" @click="eliminarCiudad(props.row.Cveentciu,props.row.Nomentciu)"></i>
                        </span>
                    </div>
                </v-client-table>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ciudades",
    data() {
        return{
            columnsCiudades: ['Cveentciu','Estado','Nomentciu'],
            tableDataCiudades: [],
            optionsCiudades: {
                footerHeadings:false,
                filterByColumn:true,
                pagination: { dropdown:false },
                perPage:10,
                perPageValues: [10,15,25,45,50],
                filterable: ['Estado','Nomentciu'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                    Estado:[]
                },
                columnsDropdown:false,
                headings: {
                    Cveentciu: '#',
                    Nomentciu: 'Ciudad',
                },
                columnsClasses: {
                    Cveentciu: 'text-center font-bold text-danger',
                    Estado: 'text-center',
                    Nomentciu: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            edit: false,
            ciudad:'',
            estado: 0
        }
    },
    mounted(){
        this.getCiudades();
    },
    methods:{
        guardarCiudad(){ let $this = this;
            if (this.estado === 0){
                $this.$toastMessage("Error!","Seleccione el estado para guardar la ciduad");
            }
            else if(this.ciudad === '' || this.ciudad.length<3){
                $this.$toastMessage('Error!','Ingrese un texto o el texto mayor a 3 caracteres!');
            }else{
                axios
                    .post('/api/Tiendas/peticiones',{solicitud:16, estado:this.estado, ciudad:this.ciudad})
                    .then(function (r){
                        if (r.data.Success){
                            $this.$toastMessage("Exito!",r.data.Mensaje);
                            $this.getCiudades();
                            $this.ciudad='';
                            $this.estado=0;
                            $this.edit=!$this.edit;
                        }
                        else{
                            $this.$toastMessage("Error!",r.data.Mensaje);
                        }
                    });
            }
        },
        getCiudades(){ let $this = this;
            axios
                .post('/api/Tiendas/peticiones',{solicitud:14})
                .then(function (r){
                    $this.tableDataCiudades = r.data;
                });
            axios
                .post('/api/Tiendas/peticiones',{solicitud:15})
                .then(function (r){
                    $this.optionsCiudades.listColumns.Estado = r.data;
                });
        },
        eliminarCiudad(id,CIU){ let $this = this;
            this.$swal.fire({
                title: '¿Eliminar Ciudad?',
                text: CIU,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'No, cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api/Tiendas/peticiones',{solicitud: 25,cveentciu:id})
                        .then(r => {
                                if (r.data.Success){
                                    $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                    $this.getCiudades();
                                }else{
                                    $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                }
                            },
                            error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                        );

                }
            });
        }
    }
}
</script>

<style scoped>

</style>
