<template>
    <div>
        <div class="row">
            <div class="col-md-4 scroll">
                <h3>Roles Atenea</h3>
                <ul class="list-group">
                    <li v-for="(items,index) in dataRoles" class="list-group-item list-group-item-action" :class="{'active':items.active}"
                        style="cursor:pointer"  @click="getPermisos(items.value,false,0)">{{index+1}}.- {{ items.text }}</li>
                </ul>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <h3 class="text-center">Permisos Atenea {{rolSelected}}</h3>
                <div class="row" v-if="showLoad">
                    <div class="col-md-12 text-center"><br><br>
                        <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                        <p><b>Buscando información por favor espere!</b></p>
                    </div>
                </div>
                <div v-for="items in dataPermisos" class="permisos">
                <h3 class="text-info">{{ items.Categoria }}</h3>
                    <ul class="list-group" v-if="dataPermisos.length>0">
                        <li v-for="(per,index) in items.Permisos" class="list-group-item list-group-item-action" :class="{'active':per.Active}"
                            style="cursor:pointer"  @click="changePermisos(per.ClaveModulo,true,per.Active)">{{index+1}}.- <i :class="per.Icono"></i> {{ per.Nombre }}</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "permisosUsuarios",
    data(){
        return{
            showLoad:false,
            dataRoles: [],
            dataPermisos: [],
            rolSelected:'',
            tipoUsuario:0,
        }
    },
    mounted() {let $this = this;
        axios.post('/api/AdminCatalogos/getRoles')
            .then(r => {
                    if (r.data.Success){
                        $this.dataRoles = [];
                        $this.dataRoles = r.data.Data;
                    }
                    else{
                        $this.dataRoles = [];
                        $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                    }
                },
                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
            );
    },
    methods:{
        getIdx(clave){let $this = this;
            var idx = 0;
            for (var i = 0; i< this.dataRoles.length; i++){
                if (clave === $this.dataRoles[i]['value']){
                    idx = i;
                }
                this.dataRoles[i].active = false;
            }
            return idx;
        },
        getPermisos(clave,type,action){let $this=this;
            this.showLoad = true;
            this.dataPermisos = [];
            var id = this.getIdx(clave);
            this.tipoUsuario=clave;
            this.dataRoles[id].active = true;
            this.rolSelected = this.dataRoles[id].text;
            axios.post('/api/AdminCatalogos/getPermisosByRoles',{cveTipo: clave,editar:type,per:action})
                .then(r => {
                        if (r.data.Success){
                            $this.dataPermisos = r.data.Data;
                            $this.showLoad = false;
                        }else{
                            $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        changePermisos(clave,type,action){let $this=this;
            this.showLoad = true;
            this.dataPermisos = [];
            axios.post('/api/AdminCatalogos/getPermisosByRoles',{cveTipo: this.tipoUsuario,editar:type,act:action,mod:clave})
                .then(r => {
                        if (r.data.Success){
                            $this.dataPermisos = r.data.Data;
                            $this.showLoad = false;
                        }else{
                            $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        }
    }
}
</script>

<style scoped>

</style>
<style >
.scroll ul{height:400px; width:100%;}
.scroll ul{overflow:hidden; overflow-y:scroll;}
.permisos ul{height:100px; width:100%;}
.permisos ul{overflow:hidden; overflow-y:scroll;}
</style>
