<template>
    <div>
        <h2 class="text-info">Datos Encuesta</h2>
        <p>Selecciona el periodo en el que aplicará la encuesta y los distintos atributos</p>
        <p class="text-danger">Está encuesta será evaluada y posteriormente publicada si es que cumple los criterios mencionados.</p>
        <div class="row">
            <div class="col-md-2 text-center">
                <br><br><br>
                <p-radio class="p-icon p-round" name="icon_solid" color="primary" style="font-size: 20px;" :value="0" v-model="dataForm.type">
                    <i slot="extra" class="icon fa fa-check"></i>
                    Moët
                </p-radio>
                <br>
                <p-radio class="p-icon p-round" name="icon_solid" color="success" style="font-size: 20px;" :value="1" v-model="dataForm.type">
                    <i slot="extra" class="icon fa fa-check"></i>
                    COMPETENCIA
                </p-radio>

            </div>
            <div class="col-md-4">
                <label>Fecha Inicio:</label>
                <v-date-picker v-model='fechaInicio' color="orange" :attributes='attrs' @input="formattedDate(true)">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="bg-white border px-2 py-1 rounded form-control"
                            :value="inputValue"
                            v-on="inputEvents"
                        />
                    </template>
                </v-date-picker>
                <label>Fecha Termino:</label>
                <v-date-picker v-model='fechaFin' color="orange" :attributes='attrs' @input="formattedDate(false)">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="bg-white border px-2 py-1 rounded form-control"
                            :value="inputValue"
                            v-on="inputEvents"
                        />
                    </template>
                </v-date-picker>
            </div>
            <div class="col-md-4">
                <h2 v-if="edicion===1"><b class="text-danger">Te encuentras editando una encuesta</b></h2>
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-4">
                <label>Áreas: <b>{{optionsAreas.length-1}}/{{dataForm.areas.length}}</b></label>
                <v-select :multiple="true" :options="optionsAreas" :placeholder="'Selecciona Área'" v-model="dataForm.areas" @input="changeAarea"></v-select>
            </div>
            <div class="col-md-4">
                <label>Canales: <b>{{optionsCanales.length-1}}/{{dataForm.canales.length}}</b></label>
                <v-select :multiple="true" :options="optionsCanales" :placeholder="'Selecciona Canal'" v-model="dataForm.canales" @input="changeCanal"></v-select>
            </div>
            <div class="col-md-4">
                <label>Departamentos: <b>{{optionsDepartamentos.length-1}}/{{dataForm.departamentos.length!=0?1:0}}</b></label>
                <v-select :multiple="false" :options="optionsDepartamentos" :placeholder="'Selecciona Departamento'" v-model="dataForm.departamentos" @input="changeDepartamentos"></v-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Agencia: <b>{{optionsAgencia.length-1}}/{{dataForm.agencia.length}}</b></label>
                <v-select :multiple="true" :options="optionsAgencia" :placeholder="'Selecciona Agencia'" v-model="dataForm.agencia"></v-select>
            </div>
            <div class="col-md-4">
                <label>Cadenas: <b>{{optionsCadenas.length-1}}/{{dataForm.cadenas.length}}</b></label>
                <v-select :multiple="true" :options="optionsCadenas" :placeholder="'Selecciona Cadena'" v-model="dataForm.cadenas" @input="changeCadena"></v-select>
            </div>
            <div class="col-md-4">
                <label>Categorias: <b>{{optionsCategorias.length}}/{{dataForm.categorias.length!=0?1:0}}</b></label>
                <v-select :multiple="false" :options="optionsCategorias" :placeholder="'Selecciona Categoria'" v-model="dataForm.categorias" @input="changeCategoria"></v-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Estados: <b>{{optionsEstados.length-1}}/{{dataForm.estados.length}}</b></label>
                <v-select :multiple="true" :options="optionsEstados" :placeholder="'Selecciona Estados'" v-model="dataForm.estados" @input="changeEstados"></v-select>
            </div>
            <div class="col-md-4">
                <label>Formatos: <b>{{optionsFormatos.length-1}}/{{dataForm.formatos.length}}</b></label>
                <v-select :multiple="true" :options="optionsFormatos" :placeholder="'Selecciona Formato'" v-model="dataForm.formatos" @input="changeFormato"></v-select>
            </div>
            <div class="col-md-4">
                <label>Marcas: <b>{{optionsMarcas.length}}/{{dataForm.marcas.length}}</b></label>
                <v-select :multiple="true" :options="optionsMarcas" :placeholder="'Selecciona Marca'" v-model="dataForm.marcas" @input="changeMarca"></v-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Ciudades: <b>{{optionsCiudades.length-1}}/{{dataForm.ciudades.length}}</b></label>
                <v-select :multiple="true" :options="optionsCiudades" :placeholder="'Selecciona Ciudad'" v-model="dataForm.ciudades" @input="changeCiudades"></v-select>
            </div>
            <div class="col-md-4">
                <label>Tiendas: <b>{{optionsTiendas.length-1}}/{{dataForm.tiendas.length}}</b></label>
                <v-select :multiple="true" :options="optionsTiendas" :placeholder="'Selecciona Tienda'" v-model="dataForm.tiendas"></v-select>
            </div>
            <div class="col-md-4">
                <label>Productos: <b>{{optionsProductos.length-1}}/{{dataForm.productos.length}}</b></label>
                <v-select :multiple="true" :options="optionsProductos" :placeholder="'Selecciona Producto'" v-model="dataForm.productos"></v-select>
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-4">
                <label>Promoción: </label>
                <v-select :multiple="false" :options="optionsPromociones" :placeholder="'Selecciona Promoción'" v-model="dataForm.promocion"></v-select>
            </div>

        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Tipo Respuesta:</label>
                <v-select :multiple="false" :options="optionsRespuestas" :placeholder="'Selecciona Respuesta'" v-model="dataForm.respuesta"></v-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Pregunta:</label>
                <textarea class="form-control" rows="4" maxlength="200" v-model="dataForm.pregunta"></textarea>
            </div>
            <div class="col-md-4">
                <label>Comentario para evaluar la aprobación de la prioridad:</label>
                <textarea class="form-control" rows="4" maxlength="200" v-model="dataForm.comentario"></textarea>
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-4">
                <label>Imagen:</label>
                <input type="file" name="myImage" accept="image/*"  class="form-control" @change="handleImage"/>
            </div>
            <i class="fa fa-eye" style="color: #0e9aef;cursor:pointer" @click="imgvisible = !imgvisible" v-show="!imgvisible"> Mostrar Imagen</i>
            <div class="col-md-4" v-show="dataForm.imagen!='' & imgvisible">
                <i class="fa fa-eye" style="color: darkred;cursor:pointer" @click="imgvisible = !imgvisible"> Ocultar imagen</i>
                <img :src="dataForm.imagen" class="img img-responsive" alt="ImagenPrioridad" />
            </div>
        </div><br>
        <div class="row" v-if="!enviarInformacion">
            <div class="col-md-4">
                <button class="btn btn-primary btn-block" type="button" @click="savePrioridad"><b>Registrar prioridad</b></button>
                <button class="btn btn-warning btn-block" type="button" @click="LimpiarForm"><b>Limpiar</b></button>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-md-4 text-center">
                <div class="spiner-example" >
                    <div class="sk-spinner sk-spinner-wave">
                        <div class="sk-rect1"></div>
                        <div class="sk-rect2"></div>
                        <div class="sk-rect3"></div>
                        <div class="sk-rect4"></div>
                        <div class="sk-rect5"></div>
                    </div>
                    <p><b>Por favor espere. Estamos trabajando su solicitud!</b></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "crearEncuesta",
    data(){
        return {
            edicion:0,
            claveEdit:0,
            enviarInformacion:false,
            message: 'Hola Mundo',
            info: null,
            imgvisible:true,
            optionsAreas: [],
            optionsAgencia: [],
            optionsEstados: [],
            optionsCiudades: [],
            optionsCanales: [],
            optionsCadenas: [],
            optionsFormatos: [],
            optionsTiendas: [],
            optionsDepartamentos: [],
            optionsCategorias: [],
            optionsMarcas: [],
            optionsProductos: [],
            optionsPromociones: [],
            optionsRespuestas: [{value:1,label:"Si / No"},{value:2,label:"Número"}],
            fechaInicio: null,
            fechaFin: null,
            attrs: [
                {
                    key: 'today',
                    highlight: true,
                    dates: new Date(),
                },
            ],
            dataForm:{
                fechaInicio:null,
                fechaFin:null,
                areas: [],
                agencia:[],
                estados:[],
                ciudades:[],
                canales:[],
                cadenas:[],
                formatos:[],
                tiendas:[],
                departamentos:[],
                categorias:[],
                marcas:[],
                productos:[],
                promocion:[],
                respuesta:[],
                pregunta:"",
                comentario:"",
                imagen:'',
                type:0
            }
        }
    },
    mounted () {
        this.getData();
    },
    methods:{
        getData(){
            this.optionsAreas=[],
                this.optionsAgencia=[],
                this.optionsEstados=[],
                this.optionsCiudades=[],
                this.optionsCanales=[],
                this.optionsCadenas=[],
                this.optionsFormatos=[],
                this.optionsTiendas=[],
                this.optionsDepartamentos=[],
                this.optionsCategorias=[],
                this.optionsMarcas=[],
                this.optionsProductos=[],
                this.optionsPromociones=[],
                this.dataForm.fechaInicio=null;
            this.dataForm.fechaFin=null;
            this.dataForm.areas= [];
            this.dataForm.agencia=[];
            this.dataForm.estados=[];
            this.dataForm.ciudades=[];
            this.dataForm.canales=[];
            this.dataForm.cadenas=[];
            this.dataForm.formatos=[];
            this.dataForm.tiendas=[];
            this.dataForm.type=0;
            this.dataForm.departamentos=[];
            this.dataForm.categorias=[];
            this.dataForm.marcas=[];
            this.dataForm.productos=[];
            this.dataForm.promocion=[];
            this.dataForm.respuesta=[];
            this.dataForm.pregunta="";
            this.dataForm.comentario="";
            this.dataForm.imagen='';
            this.fechaInicio = null;
            this.fechaFin = null;
            this.getAreas();
            this.getAgencia();
            this.getDepartamentos();
            this.getCanales();
            this.getPromociones();
        },
        formattedDate(i) {
            if (i){
                var mm = this.fechaInicio.getMonth() + 1; // getMonth() is zero-based
                var dd = this.fechaInicio.getDate();
                var fecha = [this.fechaInicio.getFullYear(),
                    (mm>9 ? '' : '0') + mm,
                    (dd>9 ? '' : '0') + dd
                ].join('-');
                this.dataForm.fechaInicio = fecha;
            }else{
                var mm = this.fechaFin.getMonth() + 1; // getMonth() is zero-based
                var dd = this.fechaFin.getDate();
                var fecha = [this.fechaFin.getFullYear(),
                    (mm>9 ? '' : '0') + mm,
                    (dd>9 ? '' : '0') + dd
                ].join('-');
                this.dataForm.fechaFin = fecha;
            }
        },
        getAreas(){
            axios
                .post('/api/Encuestas/Combos/',{combo:1})
                .then(response => (this.optionsAreas = response.data));
        },
        getAgencia(){
            axios
                .post('/api/Encuestas/Combos/',{combo:2})
                .then(response => (this.optionsAgencia = response.data));
        },
        getEstados(){
            axios
                .post('/api/Encuestas/Combos/',{combo:3})
                .then(response => (this.optionsEstados = response.data));
        },
        getCiudades(){
            axios
                .post('/api/Encuestas/Combos/',{combo:4})
                .then(response => (this.optionsCiudades = response.data));
        },
        getCanales(){
            axios
                .post('/api/Encuestas/Combos/',{combo:5})
                .then(response => (this.optionsCanales = response.data));
        },
        getCadenas(){
            axios
                .post('/api/Encuestas/Combos/',{combo:6})
                .then(response => (this.optionsCadenas = response.data));
        },
        getFormatos(){
            axios
                .post('/api/Encuestas/Combos/',{combo:7})
                .then(response => (this.optionsFormatos = response.data))
        },
        getTiendas(){
            axios
                .post('/api/Encuestas/Combos/',{combo:8})
                .then(response => (this.optionsTiendas = response.data));
        },
        getDepartamentos(){
            axios
                .post('/api/Encuestas/Combos/',{combo:9})
                .then(response => (this.optionsDepartamentos = response.data));
        },
        getPromociones(){
            axios
                .post('/api/Encuestas/Combos/',{combo:13})
                .then(response => (this.optionsPromociones = response.data));
        },
        changeAarea(){
            this.dataForm.estados = [];
            this.dataForm.ciudades = [];
            this.optionsEstados = [];
            this.optionsCiudades = [];
            axios
                .post('/api/Encuestas/Combos/',{combo:3,areas:this.dataForm.areas})
                .then(response => (this.optionsEstados = response.data));
        },
        changeEstados(){
            this.dataForm.ciudades = [];
            this.optionsCiudades = [];
            axios
                .post('/api/Encuestas/Combos/',{combo:4,estados:this.dataForm.estados})
                .then(response => (this.optionsCiudades = response.data));
        },
        changeCanal(){
            this.dataForm.cadenas = [];
            this.dataForm.formatos = [];
            this.dataForm.tiendas = [];
            this.optionsCadenas = [];
            this.optionsFormatos = [];
            this.optionsTiendas = [];
            axios
                .post('/api/Encuestas/Combos/',{combo:6,canales:this.dataForm.canales})
                .then(response => (this.optionsCadenas = response.data));

        },
        changeCadena(){
            this.dataForm.formatos = [];
            this.dataForm.tiendas = [];
            this.optionsFormatos = [];
            this.optionsTiendas = [];
            axios
                .post('/api/Encuestas/Combos/',{combo:7,cadenas:this.dataForm.cadenas})
                .then(response => (this.optionsFormatos = response.data))
        },
        changeCiudades(){
            this.dataForm.formatos = [];
            this.dataForm.tiendas = [];
            this.optionsTiendas = [];
        },
        changeFormato(){
            if (this.dataForm.formatos.length>0){
                if (this.dataForm.ciudades.length<1){
                    this.dataForm.formatos = [];
                    alertaError("Error!","Por favor seleccione por lo menos una ciudad!, para traer las tiendas correspondientes a los formatos seleccionados!")
                }else{
                    this.dataForm.tiendas = [];
                    this.optionsTiendas = [];
                    axios
                        .post('/api/Encuestas/Combos/',{combo:8,formatos:this.dataForm.formatos,ciudades:this.dataForm.ciudades})
                        .then(response => (this.optionsTiendas = response.data))
                }
            }else{
                this.optionsTiendas = [];
                this.dataForm.ciudades = [];
            }
        },
        changeDepartamentos(){
            axios
                .post('/api/Encuestas/Combos/',{combo:10,departamentos:this.dataForm.departamentos})
                .then(response => (this.optionsCategorias = response.data))
        },
        changeCategoria(){
            this.dataForm.marcas=[];
            this.optionsMarcas=[];
            axios
                .post('/api/Encuestas/Combos/',{combo:11,categorias:this.dataForm.categorias,type:this.dataForm.type})
                .then(response => (this.optionsMarcas = response.data))
        },
        changeMarca(){
            axios
                .post('/api/Encuestas/Combos/',{combo:12,marcas:this.dataForm.marcas,
                    categorias:this.dataForm.categorias})
                .then(response => (this.optionsProductos = response.data))
        },
        handleImage(e){let $this = this;
            const selectedImage = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function(event) {
                $this.dataForm.imagen = event.target.result;
            };
            reader.onerror = function(error) {
                alert(error);
            };
            reader.readAsDataURL(selectedImage);
        },
        savePrioridad() {
            if (this.dataForm.fechaInicio === null) {
                alertaError("Error!", "Por favor seleccione la fecha de inicio");
            }
            else if (this.dataForm.fechaFin === null) {
                alertaError("Error!", "Por favor seleccione la fecha de fin");
            }
            else if (this.dataForm.areas.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos un área");
            }
            else if (this.dataForm.agencia.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos una agencia");
            }
            else if (this.dataForm.estados.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos un estado");
            }
            else if (this.dataForm.ciudades.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos una ciudad");
            }
            else if (this.dataForm.canales.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos un canal");
            }
            else if (this.dataForm.cadenas.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos una cadena");
            }
            else if (this.dataForm.formatos.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos un formato");
            }
            else if (this.dataForm.tiendas.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos una tienda");
            }
            else if (this.dataForm.departamentos.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos un departamento");
            }
            else if (this.dataForm.categorias.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos una categoria");
            }
            else if (this.dataForm.marcas.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos una marca");
            }
            else if (this.dataForm.productos.length === 0) {
                alertaError("Error!", "Por favor seleccione por lo menos un producto");
            }
            else if (this.dataForm.promocion.length === 0) {
                alertaError("Error!", "Por favor seleccione el tipo de promoción");
            }
            else if (this.dataForm.respuesta.length === 0) {
                alertaError("Error!", "Por favor seleccione el tipo de respuesta");
            }
            else if (this.dataForm.pregunta.length === 0 || this.dataForm.pregunta.length <= 10) {
                alertaError("Error!", "Por favor espeficique con más detalle su pregunta, para la prioridad");
            }
            else if (this.dataForm.comentario.length === 0 || this.dataForm.comentario.length <= 10) {
                alertaError("Error!", "Por favor espeficique con más detalle el comentario de su prioridad");
            }
            else{
                if (this.edicion===0){
                    this.guardarData();
                }else{
                    this.guardarDataEdit();
                }

            }
        },
        LimpiarForm(){
            this.getData();
            if (this.edicion===1) this.edicion=0;
        },
        editar(clave){let $this = this;this.claveEdit=clave;this.edicion=1;
            console.log(clave);
            axios.post('/api/Encuestas/Combos/',{combo:18,clave:clave})
                .then(function (r){
                    alertaSuccess("Exito!",r.data.Mensaje);
                    console.log(r.data);
                    $this.dataForm.comentario = r.data.Prioridad.Comentario;
                    $this.dataForm.pregunta = r.data.Prioridad.Nomentprd;
                    $this.dataForm.respuesta = {'label':r.data.Prioridad.Tpo===1?'Si / No':'Número','value':r.data.Prioridad.Tpo};
                    $this.dataForm.promocion = {'label':r.data.Prioridad.Promocion,'value':r.data.Prioridad.Cveentpro};
                    $this.dataForm.imagen = r.data.Prioridad.Pathimg ==='' ? '':'/atenea'+r.data.Prioridad.Pathimg;
                    $this.dataForm.fechaInicio = r.data.Prioridad.Finicio;
                    $this.fechaInicio = new Date(r.data.Prioridad.Finicio);
                    $this.dataForm.fechaFin = r.data.Prioridad.Ftermino;
                    $this.fechaFin = new Date(r.data.Prioridad.Ftermino);
                    $this.dataForm.type=r.data.Prioridad.Type;
                    var a = JSON.parse(r.data.Areas);
                    setTimeout(function(){
                        if (a.length === ($this.optionsAreas.length-1)){
                            $this.dataForm.areas = [$this.optionsAreas[0]];
                            $this.changeAarea();
                        }else{
                            $this.dataForm.areas = a;
                            $this.changeAarea();
                        }
                    }, 500);
                    var ag = JSON.parse(r.data.Agencias);
                    setTimeout(function(){
                        if (ag.length === ($this.optionsAgencia.length-1)){
                            $this.dataForm.agencia = [$this.optionsAgencia[0]];
                        }else{
                            $this.dataForm.agencia = ag;
                        }
                    }, 600);
                    var e = JSON.parse(r.data.Estados);
                    setTimeout(function(){
                        if (e.length === ($this.optionsEstados.length-1)){
                            $this.dataForm.estados = [$this.optionsEstados[0]];
                            $this.changeEstados();
                        }else{
                            $this.dataForm.estados = e;
                            $this.changeEstados();
                        }
                    }, 1000);
                    var can = JSON.parse(r.data.Canales);
                    setTimeout(function(){
                        if (can.length === ($this.optionsCanales.length-1)){
                            $this.dataForm.canales = [$this.optionsCanales[0]];
                            $this.changeCanal();
                        }else{
                            $this.dataForm.canales = can;
                            $this.changeCanal();
                        }
                    }, 1000);
                    var cad = JSON.parse(r.data.Cadenas);
                    setTimeout(function(){
                        if (cad.length === ($this.optionsCadenas.length-1)){
                            $this.dataForm.cadenas = [$this.optionsCadenas[0]];
                            $this.changeCadena();
                        }else{
                            $this.dataForm.cadenas = cad;
                            $this.changeCadena();
                        }
                    }, 1000);

                    var c = JSON.parse(r.data.Ciudades);
                    setTimeout(function(){
                        if (c.length === ($this.optionsCiudades.length-1)){
                            $this.dataForm.ciudades = [$this.optionsCiudades[0]];
                        }else{
                            $this.dataForm.ciudades = c;

                        }
                    }, 1500);
                    var f = JSON.parse(r.data.Formatos);
                    setTimeout(function(){
                        if (f.length === ($this.optionsFormatos.length-1)){
                            $this.dataForm.formatos = [$this.optionsFormatos[0]];
                        }else{
                            $this.dataForm.formatos = f;
                        }
                    }, 2000);
                    setTimeout(function(){$this.changeFormato();},2300);
                    var tda = JSON.parse(r.data.Tiendas);
                    setTimeout(function(){
                        if (tda.length === ($this.optionsTiendas.length-1)){
                            $this.dataForm.tiendas = [$this.optionsTiendas[0]];
                        }else{
                            $this.dataForm.tiendas = tda;
                        }
                    }, 3000);

                    $this.dataForm.departamentos = r.data.Departamentos;
                    $this.changeDepartamentos();
                    setTimeout(function(){
                        $this.dataForm.categorias = r.data.Categorias;
                        $this.changeCategoria();
                    }, 3000);
                    var m = JSON.parse(r.data.Marcas);
                    setTimeout(function(){
                        if (tda.length === ($this.optionsMarcas.length-1)){
                            $this.dataForm.marcas = [$this.optionsMarcas[0]];

                        }else{
                            $this.dataForm.marcas = m;

                        }
                    }, 3000);
                    setTimeout(function(){$this.changeMarca(); }, 3100);
                    var p = JSON.parse(r.data.Productos);
                    setTimeout(function(){
                        if (p.length === ($this.optionsProductos.length-1)){
                            $this.dataForm.productos = [$this.optionsProductos[0]];
                        }else{
                            $this.dataForm.productos = p;
                        }
                    }, 3400);


                });
        },
        guardarData(){
            Swal.fire({
                title: '¿Registrar Encuesta?',
                text: "Se notificará por correo electrónico al supervisor!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#308c0e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, registrar!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    let $this = this;
                    this.enviarInformacion = true;
                    axios
                        .post('/api/Encuestas/Combos/',{combo:14,data:this.dataForm})
                        .then(function (r){
                            console.log(r);
                            alertaSuccess("Exito!",r.data.Mensaje)
                            axios
                                .post('/api/Encuestas/Combos/',{combo:21,Id:r.data.Clave})/// envio de email a los usuarios!
                                .then(function (r){
                                    console.log(r);
                                    $this.enviarInformacion = false;
                                    Swal.fire(
                                        'Exito!',
                                        'Correo enviado, espera la notificación de aprobación.',
                                        'success'
                                    );
                                });
                        }).
                    catch(function (error) {
                        alertaError("Error!",error);
                        alertaError("Error!","Informe este error a IT!");
                        $this.enviarInformacion = false;
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });

                }
            })
        },
        guardarDataEdit(){
            let $this = this;
            this.enviarInformacion = true;
            axios
                .post('/api/Encuestas/Combos/',{combo:19,data:this.dataForm,clave:this.claveEdit})
                .then(function (r){
                    alertaInfo("Exito!",r.data.Mensaje)
                    $this.enviarInformacion = false;
                }).
            catch(function (error) {
                alertaError("Error!",error);
                alertaError("Error!","Informe este error a IT!");
                $this.enviarInformacion = !this.enviarInformacion;
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        }
    }
}
</script>

<style scoped>

</style>
