import { render, staticRenderFns } from "./productosSimple.vue?vue&type=template&id=34f2f9e5&scoped=true&"
import script from "./productosSimple.vue?vue&type=script&lang=js&"
export * from "./productosSimple.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34f2f9e5",
  null
  
)

export default component.exports