<template>
    <div>
        <vue-tabs active-tab-color="#c3a900"
                  active-text-color="white"
                  type="pills"
                  :start-index="1">

            <v-tab title="Crear Encuesta">
                <br>
                <tabscrear-encuestas></tabscrear-encuestas>
            </v-tab>

            <v-tab title="Importar Layout">
                <br>
                <tabsload-encuestas></tabsload-encuestas>
            </v-tab>

        </vue-tabs>
    </div>
</template>

<script>
export default {
    name: "encuestasTabs"
}
</script>

<style scoped>

</style>
