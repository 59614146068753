<template>
    <div>
        <v-client-table :data="tableData" :columns="columns" :options="options" ref="ModulesTable">
            <div slot="Bndmodact" slot-scope="props">
                <span v-if="props.row.Bndmodact === 1" class="badge badge-success ripple" @click="ChangeStatus(props.row.Cveentusr,0)">Activo</span>
                <span v-else class="badge badge-danger ripple" @click="ChangeStatus(props.row.Cveentusr,1)">Inactivo</span>
            </div>
            <div slot="Icnmodsis" slot-scope="props">
                <i class="fa " :class="props.row.Icnmodsis"></i>
            </div>
        </v-client-table>
    </div>
</template>

<script>
export default {
    name: "modulos",
    data(){
        return{
            tableData: [],
            columns: ['Cvemodsis','Accion','Nommodsis','Dirmodsis','Commodsis','Bndmodact','Categoria','Icnmodsis'],
            options: {
                // see the options API
                footerHeadings:false,
                filterByColumn:true,
                perPage:10,
                perPageValues: [10,15,25,45,50],
                filterable: ['Nommodsis','Dirmodsis','Bndmodact'],
                sortable: ['Nommodsis','Dirmodsis','Bndmodact'],
                listColumns: {Bndmodact: [{text:'Activo',id:1},{text:'Inactivo',id:0}]},
                headings: {
                    Cvemodsis: '#',
                    Nommodsis: 'Nombre Modulo',
                    Bndmodact: 'Estatus',
                    Accion: 'Acciones',
                    Dirmodsis: 'Dirección',
                    Commodsis: 'Comentario',
                    Categoria: 'Categoria',
                    Icnmodsis: 'Icono',
                },
                columnsClasses: {
                    Cvemodsis: 'text-left',
                    Bndmodact: 'text-center',
                    Nommodsis: 'text-justify',
                    Dirmodsis: 'text-justify',
                    Commodsis: 'text-justify',
                    Accion: 'text-center',
                    Categoria: 'text-justify',
                    Icnmodsis: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Recopilando información, por favor espere...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods:{
        getData(){let $this = this;
            axios.post('/api/AdminCatalogos/getModulosSistema')
                .then(r => {
                        if (r.data.Success){
                            $this.tableData = [];
                            $this.tableData = r.data.Data;
                            $this.showLoad=false;
                            $this.options.listColumns.Agencia = r.data.Agencias;
                            $this.options.listColumns.Tipo = r.data.Tipos;
                            $this.options.listColumns.Rol = r.data.Roles;
                            $this.optionsRol = r.data.Roles;
                        }else{
                            $this.tableData = [];
                            $this.tableData = r.data.Data;
                            $this.showLoad=false;
                            $this.options.listColumns.Agencia = r.data.Agencias;
                            $this.options.listColumns.Tipo = r.data.Tipos;
                            $this.options.listColumns.Rol = r.data.Roles;
                            $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        }
    }
}
</script>

<style scoped>

</style>
