<template>
    <div>
        <v-select :multiple="true" :options="optionsAgencias" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedAgencias" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "agencias",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedAgencias:[],
            optionsAgencias:[]
        }
    },
    mounted() {
        this.getAreas();
    },
    methods:{
        getAreas(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/agenciaSelect')
                .then(function (r){
                    $this.optionsAgencias = r.data;
                });
        },
        setCambio(value){
            console.log(value.length)
            this.$emit('cambioAgencia');
        }

    }
}
</script>

<style scoped>

</style>
