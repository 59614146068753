<template>
    <div>
        <v-select :multiple="true" :options="optionsEstados" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedEstados" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "estados",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedEstados:[],
            optionsEstados:[],
            areas:[]
        }
    },
    mounted() {
        this.getEstados();
    },
    methods:{
        getEstados(){ let $this = this; this.selectedEstados = [];
            axios
                .post('/api/AdminCatalogos/estadosSelect', {areas: this.areas})
                .then(function (r){
                    $this.optionsEstados = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioEstado');
        }

    }
}
</script>

<style scoped>

</style>
