<template>
    <div>
        <v-select :multiple="true" :options="optionsTrades" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedTrades" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "trades",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedTrades:[],
            optionsTrades:[]
        }
    },
    mounted() {
        this.getAreas();
    },
    methods:{
        getAreas(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/tradesSelect')
                .then(function (r){
                    $this.optionsTrades = r.data;
                });
        },
        setCambio(value){
            console.log(value.length)
            this.$emit('cambioTrade');
        }

    }
}
</script>

<style scoped>

</style>
