<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row" >
            <div class="col-md-2">
                <filters-general ref="filtersReport"></filters-general>
            </div>
            <div class="col-md-10">
                <vue-tabs active-tab-color="#c3a900" active-text-color="white" type="pills" :start-index="startIndex" @tab-change="handleTabChange">
                    <v-tab title="ENCUESTAS">
                        <br>
                        <tipo-galeria :tipoGaleria="'ENCUESTAS'" ref="gEncuestas" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                    <v-tab title="FALTANTES">
                        <br>
                        <tipo-galeria :tipoGaleria="'FALTANTES'" ref="gFaltantes" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                    <v-tab title="ANAQUEL">
                        <br>
                        <tipo-galeria :tipoGaleria="'ANAQUEL'" ref="gAnaquel" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                    <v-tab title="EXHIBICIONES">
                        <br>
                        <tipo-galeria :tipoGaleria="'EXHIBICIONES'" ref="gExhibiciones" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                    <v-tab title="PRECIOS MAYOREO">
                        <br>
                        <tipo-galeria :tipoGaleria="'PMAYOREO'" ref="gPmayoreo" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                    <v-tab title="PROMOCIONES">
                        <br>
                        <tipo-galeria :tipoGaleria="'PROMOCIONES'" ref="gPromociones" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                    <v-tab title="COMENTARIOS">
                        <br>
                        <tipo-galeria :tipoGaleria="'COMENTARIOS'" ref="gComentarios" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                    <v-tab title="FOTOGRÁFICO">
                        <br>
                        <tipo-galeria :tipoGaleria="'FOTOGRÁFICO'" ref="gFotografico" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                    <v-tab title="INVENTARIO">
                        <br>
                        <tipo-galeria :tipoGaleria="'IVENTARIO'" ref="gInventario" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                    <v-tab title="RESURTIDO">
                        <br>
                        <tipo-galeria :tipoGaleria="'RESURTIDO'" ref="gResurtido" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                    <v-tab title="VENTAS">
                        <br>
                        <tipo-galeria :tipoGaleria="'VENTAS'" ref="gVentas" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                    <v-tab title="EXHIBICIONES COMPETENCIA">
                        <br>
                        <tipo-galeria :tipoGaleria="'EXHIBICIONES COMPETENCIA'" ref="gExhibicionesCompetencia" v-on:getReportButton="getReport"></tipo-galeria>
                    </v-tab>
                </vue-tabs>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "galeria",
    data(){
        return{
            startIndex:0,
        }
    },
    mounted(){

    },
    methods:{
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getReport();
            }
        },
        handleTabChange(tabIndex, newTab, oldTab){
            this.startIndex = tabIndex;
            if (this.startIndex === 0 ){
                this.$refs.filtersReport.shows.fabricantes = false;
                this.$refs.filtersReport.shows.trades = true;
                this.$refs.filtersReport.shows.marcas = false;
                this.$refs.filtersReport.shows.departamentos = false;
                this.$refs.filtersReport.shows.categorias = true;
                this.$refs.filtersReport.shows.productos = false;
                this.$refs.filtersReport.shows.texhibicion = false;
                this.$refs.filtersReport.shows.tpromocion = false;
            }
            else if (this.startIndex === 1){
                this.$refs.filtersReport.shows.fabricantes = true;
                this.$refs.filtersReport.shows.trades = false;
                this.$refs.filtersReport.shows.marcas = true;
                this.$refs.filtersReport.shows.departamentos = true;
                this.$refs.filtersReport.shows.categorias = true;
                this.$refs.filtersReport.shows.productos = true;
                this.$refs.filtersReport.shows.texhibicion = false;
            }
            else if (this.startIndex === 2){
                this.$refs.filtersReport.shows.fabricantes = true;
                this.$refs.filtersReport.shows.trades = false;
                this.$refs.filtersReport.shows.categorias = true;
                this.$refs.filtersReport.shows.marcas = false;
                this.$refs.filtersReport.shows.productos = false;
                this.$refs.filtersReport.shows.departamentos = false;
                this.$refs.filtersReport.shows.texhibicion = false;
            }
            else if (this.startIndex === 3){
                this.$refs.filtersReport.shows.texhibicion = true;
                this.$refs.filtersReport.shows.fabricantes = true;
                this.$refs.filtersReport.shows.trades = false;
                this.$refs.filtersReport.shows.categorias = true;
                this.$refs.filtersReport.shows.marcas = true;
                this.$refs.filtersReport.shows.productos = true;
                this.$refs.filtersReport.shows.departamentos = true;
            }
            else if (this.startIndex === 4){
                this.$refs.filtersReport.shows.texhibicion = false;
                this.$refs.filtersReport.shows.fabricantes = true
                this.$refs.filtersReport.shows.trades = false;
                this.$refs.filtersReport.shows.categorias = true;
                this.$refs.filtersReport.shows.marcas = true;
                this.$refs.filtersReport.shows.productos = true;
                this.$refs.filtersReport.shows.departamentos = true;
            }
            else if (this.startIndex === 5){
                this.$refs.filtersReport.shows.texhibicion = false;
                this.$refs.filtersReport.shows.tpromocion = true;
                this.$refs.filtersReport.shows.trades = false;
                this.$refs.filtersReport.shows.fabricantes = true;
                this.$refs.filtersReport.shows.categorias = true;
                this.$refs.filtersReport.shows.marcas = true;
                this.$refs.filtersReport.shows.productos = true;
                this.$refs.filtersReport.shows.departamentos = true;
            }
            else if (this.startIndex === 6){
                this.$refs.filtersReport.shows.texhibicion = false;
                this.$refs.filtersReport.shows.tpromocion = false;
                this.$refs.filtersReport.shows.fabricantes = false;
                this.$refs.filtersReport.shows.categorias = false;
                this.$refs.filtersReport.shows.trades = false;
                this.$refs.filtersReport.shows.marcas = false;
                this.$refs.filtersReport.shows.productos = false;
                this.$refs.filtersReport.shows.departamentos = false;
            }
            else if (this.startIndex === 7){
                this.$refs.filtersReport.shows.texhibicion = false;
                this.$refs.filtersReport.shows.tpromocion = false;
                this.$refs.filtersReport.shows.fabricantes = true;
                this.$refs.filtersReport.shows.categorias = true;
                this.$refs.filtersReport.shows.trades = false;
                this.$refs.filtersReport.shows.marcas = true;
                this.$refs.filtersReport.shows.productos = true;
                this.$refs.filtersReport.shows.departamentos = false;
            }
            else if (this.startIndex === 11){
                this.$refs.filtersReport.shows.texhibicion = false;
                this.$refs.filtersReport.shows.tpromocion = false;
                this.$refs.filtersReport.shows.fabricantes = false;
                this.$refs.filtersReport.shows.categorias = false;
                this.$refs.filtersReport.shows.trades = false;
                this.$refs.filtersReport.shows.marcas = false;
                this.$refs.filtersReport.shows.productos = false;
                this.$refs.filtersReport.shows.departamentos = false;
            }






        },
        getReport(){
            var filters = this.$refs.filtersReport.filters;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }
            else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }
            else{
                if (this.startIndex === 0 ){
                    this.$refs.gEncuestas.filtros = filters;
                    this.$refs.gEncuestas.getData();
                }
                else if(this.startIndex === 1){
                    this.$refs.gFaltantes.filtros = filters;
                    this.$refs.gFaltantes.getData();
                }
                else if(this.startIndex === 2){
                    this.$refs.gAnaquel.filtros = filters;
                    this.$refs.gAnaquel.getData();
                }
                else if(this.startIndex === 3){
                    this.$refs.gExhibiciones.filtros = filters;
                    this.$refs.gExhibiciones.getData();
                }
                else if(this.startIndex === 4){
                    this.$refs.gPmayoreo.filtros = filters;
                    this.$refs.gPmayoreo.getData();
                }
                else if(this.startIndex === 5){
                    this.$refs.gPromociones.filtros = filters;
                    this.$refs.gPromociones.getData();
                }
                else if(this.startIndex === 6){
                    this.$refs.gComentarios.filtros = filters;
                    this.$refs.gComentarios.getData();
                }
                else if(this.startIndex === 7){
                    this.$refs.gFotografico.filtros = filters;
                    this.$refs.gFotografico.getData();
                }
                else if(this.startIndex === 8){
                    this.$refs.gInventario.filtros = filters;
                    this.$refs.gInventario.getData();
                }
                else if(this.startIndex === 9){
                    this.$refs.gResurtido.filtros = filters;
                    this.$refs.gResurtido.getData();
                }
                else if(this.startIndex === 11){
                    this.$refs.gExhibicionesCompetencia.filtros = filters;
                    this.$refs.gExhibicionesCompetencia.getData();
                }
                else{
                    this.$refs.gVentas.filtros = filters;
                    this.$refs.gVentas.getData();
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
