<template>
    <div>
        <div class="large-12 medium-12 small-12 cell">
            <label>File
                <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
            </label>
            <button @click="submitFile()">Submit</button><br>
            <textarea class="form-control" rows=35 cols=120 id="ms_word_filtered_html" v-model="read"></textarea>
        </div>
    </div>
</template>

<script>
export default {
    name: "loadtxt",
    data(){
        return{
            file: '',
            read:'asdf',
        }
    },
    mounted() {

    },
    methods:{
        /*
         Submits the file to the server
       */
        submitFile(){

            axios.post( '/api/WebServices/respaldoRuta',{resp:this.read},
            ).then(function(r){
                console.log('SUCCESS!!');
                console.log(r.data);
            })
                .catch(function(){
                    console.log('FAILURE!!');
                    console.log(r.data);
                });
        },

        /*
          Handles a change on the file upload
        */
        handleFileUpload(){ let $this = this;
            this.file = this.$refs.file.files[0];
            var reader = new FileReader();
            // Closure to capture the file information.
            reader.onload = (function(theFile) {
                return function(e) {

                    $this.read = e.target.result ;
                    //var textoUtf8 = encode_utf8(e.target.result);

                };
            })(this.file);

            // Read in the image file as a data URL.
            reader.readAsText(this.file);
        }
    }
}
</script>

<style scoped>

</style>
