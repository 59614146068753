<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="col-md-3">
                    <p class="font-weight-bold" :class="{'text-danger':!showImport,'text-info':showImport}"
                       style="cursor: pointer" @click="showImport=!showImport">{{showImport ? 'Ocultar importación':'Importar archivo'}}</p>
                </div>
            </div>
            <div class="col-md-3" v-if="showImport">
                <h5 class="text-info">Cargar layout</h5>
                <div class="form-group">
                    <label class="control-label">Archivo</label>
                    <div>
                        <input type="file"  id="archivo" name="archivo" class="form-control" required="" ref="fileRutas"
                               v-on:change="handleFileUpload()" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                    </div>
                </div>
                <button v-if="!loadfile" class="btn btn-primary text-center" type="button" @click="submitFile()"><strong>Cargar archivo</strong></button><br><br>
                <div v-if="loadfile" class="text-center">
                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                    <p>Procesando archivo, espere...</p>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <b>Tiendas Importadas:</b> <b class="text-info">{{tTiendas}}</b><br>
                        <b>Productos Asignados:</b> <b class="text-info">{{pAsignados}}</b><br>
                        <b>Total Procesados:</b> <b class="text-info">{{tProcesados}}</b><br>
                        <b>Total Tiendas Error:</b> <b class="text-info">{{tTiendasError}}</b><br>
                        <b>Filas Error:</b> <b class="text-info">{{tTiendasErrorName}}</b><br>
                        <b>Total Productos Error:</b> <b class="text-info">{{tProductosError}}</b><br>
                        <b>Total Productos Ya Asigandos:</b> <b class="text-info">{{yaAsignados}}</b><br>
                        <b>Filas Error:</b> <b class="text-info">{{tProductosErrorName}}</b>
                    </div>
                </div>
            </div>
            <div :class="{'col-md-12':!showImport,'col-md-9':showImport}">
                <div class="row">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-6">
                                <label><b>Selecciona La tienda:</b></label>
                                <select-tiendas :placeholderm="' La Tienda'" v-on:cambioTienda="showPtos" ref="selTienda"></select-tiendas>
                            </div>
                            <div class="col-md-3" v-show="!showNewRel"><br>
                                <button class="btn btn-outline btn-success dim" type="button" @click="showNewRel=!showNewRel"
                                        ><i class="fa fa-box-open"></i> ASIGNACIÓN PRODUCTO</button>
                            </div>
                            <div class="col-md-6" v-show="showNewRel">
                                <label><b>Selecciona El Producto:</b></label>
                                <select-productos :placeholderm="' El Producto'" ref="selProducto" v-on:cambioProducto="asignarPto"></select-productos>
                            </div>
                        </div>
                        <br><br>
                        <div class="col-md-12">
                            <div class="text-center" v-if="!loadfile">
                                <button class="btn btn-success btn-with-icon" @click="descargarArchivo">Descargar &nbsp;&nbsp;<i class="fa fa-file-excel"></i></button>
                            </div>
                            <div v-if="loadfile" class="text-center">
                                <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                <p>Procesando archivo, espere...</p>
                            </div>
                        </div>
                        <div class="text-center">
                            <b>Productos Asignados: {{tableDataProductos.length}}</b>
                        </div>

                        <v-client-table :data="tableDataProductos" :columns="columnsProductos" :options="optionsProductos" class="table-responsive" ref="tableProductos">
                            <div slot="Cdgentpto" slot-scope="props">
                                {{props.row.Cdgentpto}}<br>
                                <i class="fas" style="cursor:pointer" :class="{'fa-check text-success':props.row.Estatus === '1','fa-times text-danger':props.row.Estatus !== '1'}"
                                   @click="changeEstatus(props.row.Cdgentpto,props.row.Estatus,props.row.Id)"></i>
                            </div>
                            <div slot="Pathimg" slot-scope="props">
                                <i class="la la-camera-retro" style="color: blue; cursor: pointer" @click="showImgPto(props.row.Pathimg,props.row.Nomentpto)"></i>
                            </div>
                        </v-client-table>
                    </div>
                    <div class="col-md-2" >
                        <transition name="fade">
                            <div class="text-center">
                                <img :src="pathImg" alt="img Producto" class="img-fluid" v-if="show"><br>
                                <p class="tx-bold">{{ptoNom}}</p>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import selectTiendas from './../../../components/filters/tiendasSimple'
import selectPto from './../../../components/filters/productosSimple'
export default {
    name: "asignPtosTiendas",
    components:{
        "select-tiendas":selectTiendas,
        "select-productos":selectPto
    },
    data(){
        return{
            show: false,
            showNewRel: false,
            pathImg:'',
            ptoNom:'',
            loadfile:false,
            showImport:false,
            file: '',
            nameFile:'',
            tTiendas:0,
            pAsignados:0,
            tProcesados:0,
            tTiendasError:0,
            tTiendasErrorName:"",
            tProductosError:0,
            yaAsignados:0,
            tProductosErrorName:"",
            dataPto:null,
            columnsProductos: ['Cdgentpto','Nomentpto','Departamento','Fabricante','Marca','Categoria','CreatedAt','Usuario','Pathimg'],
            tableDataProductos: [],
            optionsProductos: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Cdgentpto','Nomentpto','Marca','Departamento','Categoria'],
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Cdgentpto: 'Código',
                    Nomentpto: 'Producto',
                    Cdgmoet: 'Moët',
                    Fchcrt: 'Alta',
                    Pathimg: 'Img',
                    Usuario: 'Usuario',
                },
                columnsClasses: {
                    Cdgentpto: 'text-center font-bold text-danger',
                    Nomentpto: 'text-justify',
                    Cdgmoet: 'text-center font-bold text-info',
                    Fabricante: 'text-center',
                    Marca: 'text-center',
                    Departamento: 'text-center',
                    Categoria: 'text-center',
                    Fchcrt: 'text-center tx-10',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
        }
    },
    methods:{
        async descargarArchivo(){
            this.loadfile = true;
            await this.$peticionesApiFilesDownload('/api/Productos/exportLayoutToExcel','ProductosTiendas.xlsx',[]);
            this.loadfile = false;
        },
        asignarPto(){let $this = this;
            let a = this.tableDataProductos.findIndex(object => {
                return object.Cdgentpto === $this.$refs.selProducto.selectedProductos.value;
            });
            if (a !== -1){
                $this.$toastMessage('warning','Alerta: producto ya asignado!',4000,'center');
            }
            else{
                axios.post('/api/Productos/asignPto',{
                    pto:$this.$refs.selProducto.selectedProductos.value,
                    tienda:$this.$refs.selTienda.selectedTiendas.value})
                    .then(r => {
                            $this.$toastMessage('success','Éxito: acción realizada!',1000,'top-right');
                            $this.$refs.selProducto.selectedProductos = null;
                            $this.showPtos();
                        },
                        error => {
                            $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        }
                    );
            }
        },
        findIdx(findId){let $this = this;
            let idn = 0; let a = 0;
            for(a;a<$this.tableDataProductos.length;a++){
                if ($this.tableDataProductos[a].Id === findId){
                    idn = a;
                }
            }
            return idn;
        },
        changeEstatus(pto,estatus,id){let $this=this;
            let a = this.findIdx(id);
            axios.post('/api/Productos/changeEstatus',{pto:pto,estatus:estatus,id:id})
                .then(r => {
                    let d = estatus === '1' ? '0' : '1' ;
                        $this.tableDataProductos[a].Estatus = d;
                        $this.$toastMessage('success','Éxito: acción realizada!',1000,'top-right');
                    },
                    error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    }
                );
        },
        showPtos(){ let $this = this; $this.showNewRel=false;
            $this.tableDataProductos = [];
            if (this.$refs.selTienda.selectedTiendas != null){
                axios.post('/api/Productos/getPtosTiendasCategorias',{ data:this.$refs.selTienda.selectedTiendas.value })
                    .then(r => {
                            $this.tableDataProductos = r.data;
                        },
                        error => {
                            $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        }
                    );
            }

        },
        showImgPto(img,producto){ let $this = this;
            this.pathImg='/theme/img/loads/pelota.gif';
            setTimeout(function(){
                $this.pathImg = img;
                $this.show = true;
                $this.ptoNom = producto;
            }, 700);

        },
        handleFileUpload(){
            this.file = this.$refs.fileRutas.files[0];
        },
        submitFile(){ let $this = this;
            if (this.file === ''){
                $this.$toastMessage('error','Seleccione un archivo XLSX para procesar',4000,'center');
            }
            else{
                this.loadfile = true;
                let formData = new FormData();
                /*
                    Add the form data we need to submit
                */
                formData.append('file', this.file);
                /*
                  Make the request to the POST /single-file URL
                */
                axios.post( '/api/Productos/importPtoLayoutCategorias',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(r){
                    if (r.data.Success){
                        $this.$swal.fire({
                            icon: 'success',
                            title: 'ÉXITO',
                            text:  r.data.Mensaje,
                            footer: '<b>puede ver el resumen ahora!</b>'
                        });
                        $this.tTiendas=r.data.tTiendas;
                            $this.pAsignados=r.data.pAsignados;
                            $this.tProcesados=r.data.tProcesados;
                            $this.tTiendasError=r.data.tTiendasError;
                            $this.tTiendasErrorName=r.data.tTiendasErrorName;
                            $this.tProductosError=r.data.tProductosError;
                            $this.yaAsignados=r.data.yaAsignados;
                            $this.tProductosErrorName=r.data.tProductosErrorName;
                    }
                    else{
                        $this.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: 'Archivo procesado con errores!',
                            footer: '<b>' + r.data.Mensaje + '</b>'
                        });
                    }
                    $this.file = '';$this.loadfile = false;$this.$refs.fileRutas.value = null;
                })
                    .catch(function(){
                        $this.$toastMessage('error','Error no definido, reportar a IT',4000,'center');
                        $this.file = '';$this.loadfile = false;$this.$refs.fileRutas.value = null;
                    });
            }

        },
    }
}
</script>

<style scoped>

</style>
