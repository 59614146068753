<template>
    <div>
        <v-select :multiple="false" :options="optionsTiposGestores" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedTiposGestores" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "tipogestores",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedTiposGestores:[],
            optionsTiposGestores:[]
        }
    },
    mounted() {
        this.getGestores();
    },
    methods:{
        getGestores(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/getTpoUsers')
                .then(function (r){
                    $this.optionsTiposGestores = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioTipoGestor');
        }
    }
}
</script>

<style scoped>

</style>
