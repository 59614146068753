<template>
    <div>
        <v-select :multiple="false" :options="optionsTiendas" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedTiendas" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "tiendasGeneral",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedTiendas:null,
            optionsTiendas:[],
        }
    },
    mounted() {
        this.getTiendas();
    },
    methods:{
        getTiendas(){ let $this = this;
            axios
                .post('/api/AdminCatalogos/tiendasSelect')
                .then(function (r){
                    $this.optionsTiendas = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioTienda');
        }
    }
}
</script>

<style scoped>

</style>
