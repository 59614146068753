<template>
    <div>
        <vue-tabs active-tab-color="#c3a900"
                  active-text-color="white"
                  type="pills"
                  :start-index="1">

            <v-tab title="PRODUCTOS MOËT MÉXICO">
                <br>
                <productostable-component></productostable-component>
            </v-tab>

            <v-tab title="CATALOGOS PRODUCTOS">
                <br>
                <catalogospto-component></catalogospto-component>
            </v-tab>

            <v-tab title="PRODUCTOS TIENDAS">
                <br>
                <import-component></import-component>
            </v-tab>
            <v-tab title="PRODUCTOS CATEGORIAS">
                <br>
                <productos-categorias></productos-categorias>
            </v-tab>
        </vue-tabs>

    </div>
</template>

<script>
import tblPtos from "./tableptos"
import catPtos from "./catalogos"
import importPtos from "./asignPtosTiendas"
import categoriasPtosTiendas from "./categoriasPtosTiendas"
export default {
    name: "productos",
    components:{
        "productostable-component": tblPtos,
        "catalogospto-component": catPtos,
        "import-component": importPtos,
        "productos-categorias": categoriasPtosTiendas,
    }
}
</script>

<style scoped>

</style>
