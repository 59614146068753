<template>
    <div>
        <label>Fecha de inicio:</label><br>
        <date-picker v-model="filters.timeInit" valueType="'YYYY-MM-DD'"></date-picker>
        <br><label>Fecha de termino:</label><br>
        <date-picker v-model="filters.timeEnd" valueType="'YYYY-MM-DD'"></date-picker><br>
        <label class="typo__label">Agencias:</label>
        <multiselect v-model="filters.agencias" :options="optionsAgencias" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectAgencia" ref="selectAgencia">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodasAgencias ? 'Todas' : values.length > 1 ? values.length + ' agencias' : values.length + ' agencia' }}</span></template>
        </multiselect>
        <label class="typo__label">Areas:</label>
        <multiselect v-model="filters.areas" :options="optionsAreasReal" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectArea">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodasAreas ? 'Todas' : values.length > 1 ? values.length + ' areas' : values.length + ' area' }} </span></template>
        </multiselect>
        <label class="typo__label">Tipo Gestor:</label>
        <multiselect v-model="filters.tiposGestores" :options="optionsTiposGestores" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectTipoGestor">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodosTiposGestores ? 'Todos' : values.length > 1 ? values.length + ' tipos' : values.length + ' tipo' }}</span></template>
        </multiselect>
        <label class="typo__label">Gestores:</label>
        <multiselect v-model="filters.gestores" :options="optionsGestoresReal" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectGestor">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodosGestores ? 'Todos' : values.length > 1 ? values.length + ' gestores' : values.length + ' gestor' }}</span></template>
        </multiselect>
        <label class="typo__label">Estados:</label>
        <multiselect v-model="filters.estados" :options="optionsEstadosReal" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectEstado">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodosEstados ? 'Todos' : values.length > 1 ? values.length + ' estados' : values.length + ' estado' }}</span></template>
        </multiselect>
        <label class="typo__label">Ciudades:</label>
        <multiselect v-model="filters.ciudades" :options="optionsCiudadesReal" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name"  @input="SelectCiudad">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodasCiudades ? 'Todas' : values.length > 1 ? values.length + ' ciudades' : values.length + ' ciudad' }}</span></template>
        </multiselect>
        <label class="typo__label">Canales:</label>
        <multiselect v-model="filters.canales" :options="optionsCanalesReal" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectCanal">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodosCanales ? 'Todos' : values.length > 1 ? values.length + ' canales' : values.length + ' canal' }}</span></template>
        </multiselect>
        <label class="typo__label">Cadenas:</label>
        <multiselect v-model="filters.cadenas" :options="optionsCadenaReal" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectCadena">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodasCadenas ? 'Todos' : values.length > 1 ? values.length + ' cadenas' : values.length + ' cadena' }}</span></template>
        </multiselect>
        <label class="typo__label">Formatos:</label>
        <multiselect v-model="filters.formatos" :options="optionsFormatosReal" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name"  @input="SelectFormato">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodosFormatos ? 'Todos' : values.length > 1 ? values.length + ' formatos' : values.length + ' formato' }}</span></template>
        </multiselect>
        <label class="typo__label">Tiendas:</label>
        <multiselect v-model="filters.tiendas" :options="optionsTiendasReal" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectTienda">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodasTiendas ? 'Todos' : values.length > 1 ? values.length + ' tiendas' : values.length + ' tienda' }}</span></template>
        </multiselect>
        <label class="typo__label">Departamentos:</label>
        <multiselect v-model="filters.departamentos" :options="optionsDepartamentosReal" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectDepartamento">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodosDepartamentos ? 'Todos' : values.length > 1 ? values.length + ' departamentos' : values.length + ' departamento' }}</span></template>
        </multiselect>
        <label class="typo__label">Categorias:</label>
        <multiselect v-model="filters.categorias" :options="optionsCategoriasReal" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectCategoria">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodasCategorias ? 'Todas' : values.length > 1 ? values.length + ' categorias' : values.length + ' categoria' }}</span></template>
        </multiselect>
        <label class="typo__label">Fabricantes:</label>
        <multiselect v-model="filters.fabricantes" :options="optionsFabricantesReal" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Seleccione:" label="name" track-by="name" @input="SelectFabricante">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ selectTodosFabricantes ? 'Todos' : values.length > 1 ? values.length + ' fabriccantes' : values.length + ' fabricante' }}</span></template>
        </multiselect>
        <br><br><br><br>
    </div>
</template>

<script>
export default {
    name: "FilterSemaforoFaltantes",
    data() {
        return {
            selectTodasAgencias:false,
            selectTodasAreas:false,
            selectTodosTiposGestores:false,
            selectTodosGestores:false,
            selectTodosEstados:false,
            selectTodasCiudades:false,
            selectTodosCanales:false,
            selectTodasCadenas:false,
            selectTodosFormatos:false,
            selectTodasTiendas:false,
            selectTodosDepartamentos:false,
            selectTodasCategorias:false,
            optionsAgencias:[],
            optionsAgenciasReal:[],
            optionsAreas:[],
            optionsAreasReal:[],
            optionsTiposGestores:[],
            optionsGestores:[],
            optionsGestoresReal:[],
            optionsEstados:[],
            optionsEstadosReal:[],
            optionsCiudades:[],
            optionsCiudadesReal:[],
            optionsCanales:[],
            optionsCanalesReal:[],
            optionsCadena:[],
            optionsCadenaReal:[],
            optionsFormatos:[],
            optionsFormatosReal:[],
            optionsTiendas:[],
            optionsTiendasReal:[],
            optionsDepartamentos:[],
            optionsDepartamentosReal:[],
            optionsCategorias:[],
            optionsCategoriasReal:[],
            optionsFabricantes:[],
            optionsFabricantesReal:[],
            filters:{
                timeInit: null,
                timeEnd: null,
                agencias:null,
                agenciasReal:null,
                areas:null,
                areasReal:null,
                tiposGestores:null,
                gestores:null,
                estados:null,
                estadosReal:null,
                ciudades:null,
                ciudadesReal:null,
                canales:null,
                canalesReal:null,
                cadenas:null,
                cadenasReal:null,
                formatosReal:null,
                formatos:null,
                tiendas:null,
                tiendasReal:null,
                departamentos:null,
                departamentosReal:null,
                categorias:null,
                categoriasReal:null,
                fabricantes:null,
                fabricantesReal:null,
            },

        }
    },
    mounted() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var date = '' + yyyy + '-' + mm + '-' + dd + '';
        this.timeInit = '\'' + date.toString() + '\'';
        this.timeEnd = '\'' + date.toString() + '\'';
        this.getAreas();
        this.getAgencias();
        this.getTiposGestores();
        this.getGestores();
        this.getEstados();
        this.getCiudades();
        this.getCanales();
        this.getCadena();
        this.getFormatos();
        this.getTiendas();
        this.getDepartamentos();
        this.getCategorias();
        this.getFabricantes();
    },
    methods:{
        getAgencias(){let $this = this;
            axios.post('/api/AdminCatalogos/getAgencias')
                .then(r => {
                        if (r.data.Success){
                            $this.optionsAgencias = r.data.Data;
                            $this.optionsAgenciasReal = r.data.Data;
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectAgencia(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.agencias = [];
                    $this.filters.agencias.push({
                        "name":'TODAS',
                        "value":0,
                    })
                }else{
                    $this.filters.agenciasReal = $this.filters.agencias;
                }
            });
            if (selectedOption.length === 0){ $this.filters.agenciasReal = $this.filters.agencias; $this.optionsAreasReal = $this.optionsAreas; }
            else{$this.optionsAreasReal = [];}
            $this.filters.agencias.forEach(function (value,key){
                $this.optionsAreas.forEach(function (valueIn,keyIn){
                    if (value.value === 0){
                        $this.selectTodasAgencias = true;
                        $this.filters.agenciasReal = $this.optionsAgenciasReal;
                        $this.optionsAreasReal = $this.optionsAreas;
                        return;
                    }
                    if (parseInt(value.value) === parseInt(valueIn.agencia)){
                        $this.selectTodasAgencias = false;
                        $this.optionsAreasReal.push({name: '' + valueIn.name+ '', value:valueIn.value, agencia:''+valueIn.agencia+''});
                    }
                });
            });
        },
        getAreas(){let $this = this;
            axios.post('/api/AdminCatalogos/getAreas')
                .then(r => {
                        if (r.data.Success){$this.optionsAreas = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectArea(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.areas = [];
                    $this.filters.areas.push({
                        "name":'TODAS',
                        "value":0,
                        "agencia":0
                    })
                }else{
                    $this.filters.areasReal = $this.filters.areas;
                }
            });
            if (selectedOption.length === 0){ $this.filters.areasReal = $this.filters.areas; $this.optionsEstadosReal = $this.optionsEstados; }
            else{$this.optionsEstadosReal = [];}
            this.filters.estados = null;
            this.filters.areas.forEach(function (value,key){
                if (value.value === 0){
                    $this.selectTodasAreas = true;
                    $this.filters.areasReal = $this.optionsAreasReal;
                    $this.optionsEstadosReal = $this.optionsEstados;
                    return;
                }
                $this.optionsEstados.forEach(function (valueIn,keyIn){
                    if (parseInt(value.value) === parseInt(valueIn.area)){
                        $this.selectTodosEstados = false;
                        $this.optionsEstadosReal.push({name: '' + valueIn.name+ '', value:valueIn.value, area: ''+ valueIn.area +''});
                    }
                });
            });
        },
        getTiposGestores(){let $this = this;
            axios.post('/api/AdminCatalogos/tiposGestores')
                .then(r => {
                        if (r.data.Success){$this.optionsTiposGestores = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectTipoGestor(selectedOption, id){let $this = this;
            $this.optionsGestoresReal = [];
            $this.filters.gestores = null;
            this.filters.tiposGestores.forEach(function (value,key){
                $this.selectTodosTiposGestores = value.value === 0;
                $this.optionsGestores.forEach(function (valueIn,keyIn){
                    if ($this.selectTodosTiposGestores){
                        $this.optionsGestoresReal = $this.optionsGestores;
                    }
                    else{

                        if (parseInt(value.value) === parseInt(valueIn.rol)){
                            $this.selectTodosTiposGestores = false;
                            $this.optionsGestoresReal.push({name: '' + valueIn.name+ '', value:valueIn.value, rol: valueIn.rol});
                        }
                    }
                });
            });
        },
        getGestores(){let $this = this;
            axios.post('/api/AdminCatalogos/gestores')
                .then(r => {
                        if (r.data.Success){$this.optionsGestores = r.data.Data;$this.optionsGestoresReal = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectGestor(selectedOption, id){let $this = this;
            this.filters.gestores.forEach(function (value,key){
                $this.selectTodosGestores = value.value === 0;
            });
        },
        getEstados(){let $this = this;
            axios.post('/api/AdminCatalogos/estados')
                .then(r => {
                        if (r.data.Success){$this.optionsEstados = r.data.Data;$this.optionsEstadosReal = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectEstado(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.estados = [];
                    $this.filters.estados.push({
                        "name":'TODOS',
                        "value":0,
                        "area":0,
                    })
                }else{
                    $this.filters.estadosReal = $this.filters.estados;
                }
            });
            if (selectedOption.length === 0){ $this.filters.estadosReal = $this.filters.estados; $this.optionsCiudadesReal = $this.optionsCiudades;}
            else{$this.optionsCiudadesReal = [];}
            this.filters.ciudades = null;
            this.filters.estados.forEach(function (value,key){
                if (value.value === 0){
                    $this.selectTodosEstados = true;
                    $this.filters.estadosReal = $this.optionsEstadosReal;
                    $this.optionsCiudadesReal = $this.optionsCiudades;
                    return;
                }
                $this.optionsCiudades.forEach(function (valueIn,keyIn){
                    if (parseInt(value.value) === parseInt(valueIn.estado)){
                        $this.selectTodosEstados = false;
                        $this.optionsCiudadesReal.push({name: '' + valueIn.name+ '', value:valueIn.value,estado:''+valueIn.estado+''});
                    }
                });
            });
        },
        getCiudades(){let $this = this;
            axios.post('/api/AdminCatalogos/ciudades')
                .then(r => {
                        if (r.data.Success){$this.optionsCiudades = r.data.Data; $this.optionsCiudadesReal = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectCiudad(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.ciudades = [];
                    $this.filters.ciudades.push({
                        "name":'TODAS',
                        "value":0,
                        "estado":0,
                    })
                }else{
                    $this.filters.ciudadesReal = $this.filters.ciudades;
                }
            });
            if (selectedOption.length === 0){ $this.filters.ciudadesReal = $this.filters.ciudades; }
            this.filters.ciudades.forEach(function (value,key){
                if (value.value === 0){
                    $this.selectTodasCiudades = true;
                    $this.filters.ciudadesReal = $this.optionsCiudadesReal;
                    return;
                }
                $this.optionsCiudades.forEach(function (valueIn,keyIn){
                    if (parseInt(value.value) === parseInt(valueIn.estado)){
                        $this.selectTodasCiudades = false;
                        $this.optionsCiudadesReal.push({name: '' + valueIn.name+ '', value:valueIn.value});
                    }
                });
            });
        },
        getCanales(){let $this = this;
            axios.post('/api/AdminCatalogos/canales')
                .then(r => {
                        if (r.data.Success){$this.optionsCanales = r.data.Data; $this.optionsCanalesReal = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectCanal(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.canales = [];
                    $this.filters.canales.push({
                        "name":'TODOS',
                        "value":0,
                    })
                }else{
                    $this.filters.canalesReal = $this.filters.canales;
                }
            });
            if (selectedOption.length === 0){ $this.filters.canalesReal = $this.filters.canales; $this.optionsCadenaReal = $this.optionsCadena; }
            else{$this.optionsCadenaReal = [];}
            $this.filters.canales.forEach(function (value,key){
                $this.optionsCadena.forEach(function (valueIn,keyIn){
                    if (value.value === 0){
                        $this.selectTodosCanales = true;
                        $this.filters.canalesReal = $this.optionsCanalesReal;
                        $this.optionsCadenaReal = $this.optionsCadena;
                        return;
                    }
                    if (parseInt(value.value) === parseInt(valueIn.canal)){
                        $this.selectTodosCanales = false;
                        $this.optionsCadenaReal.push({name: '' + valueIn.name+ '', value:valueIn.value, canal:''+valueIn.canal+''});
                    }
                });
            });
        },
        getCadena(){let $this = this;
            axios.post('/api/AdminCatalogos/cadenas')
                .then(r => {
                        if (r.data.Success){$this.optionsCadena = r.data.Data;$this.optionsCadenaReal = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectCadena(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.cadenas = [];
                    $this.filters.cadenas.push({
                        "name":'TODAS',
                        "value":0,
                        "canal":0,
                    })
                }else{
                    $this.filters.cadenasReal = $this.filters.cadenas;
                }
            });
            if (selectedOption.length === 0){ $this.filters.cadenasReal = $this.filters.cadenas; $this.optionsFormatosReal = $this.optionsFormatos;}
            else{$this.optionsFormatosReal = [];}
            this.filters.formatos = null;
            this.filters.cadenas.forEach(function (value,key){
                if (value.value === 0){
                    $this.selectTodasCadenas = true;
                    $this.filters.cadenasReal = $this.optionsCadenaReal;
                    $this.optionsFormatosReal = $this.optionsFormatos;
                    return;
                }
                $this.optionsFormatos.forEach(function (valueIn,keyIn){
                    if (parseInt(value.value) === parseInt(valueIn.cadena)){
                        $this.selectTodasCadenas = false;
                        $this.optionsFormatosReal.push({name: '' + valueIn.name+ '', value:valueIn.value, cadena:''+valueIn.cadena+''});
                    }
                });
            });
        },
        getFormatos(){let $this = this;
            axios.post('/api/AdminCatalogos/formatos')
                .then(r => {
                        if (r.data.Success){$this.optionsFormatos = r.data.Data;$this.optionsFormatosReal = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectFormato(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.formatos = [];
                    $this.filters.formatos.push({
                        "name":'TODOS',
                        "value":0,
                        "cadena":0,
                    })
                }else{
                    $this.filters.formatosReal = $this.filters.formatos;
                }
            });
            if (selectedOption.length === 0){ $this.filters.formatosReal = $this.filters.formatos; $this.optionsTiendasReal = $this.optionsTiendas;}
            else{$this.optionsTiendasReal = [];}
            this.filters.tiendas = null;
            this.filters.formatos.forEach(function (value,key){
                if (value.value === 0){
                    $this.selectTodosFormatos = true;
                    $this.filters.formatosReal = $this.optionsFormatos;
                    $this.optionsTiendasReal = $this.optionsTiendas;
                    return;
                }
                $this.optionsTiendas.forEach(function (valueIn,keyIn){
                    if (parseInt(value.value) === parseInt(valueIn.formato)){
                        $this.selectTodosFormatos = false;
                        $this.optionsTiendasReal.push({name: '' + valueIn.name+ '', value:valueIn.value, formato:''+valueIn.formato+''});
                    }
                });
            });
        },
        getTiendas(){let $this = this;
            axios.post('/api/AdminCatalogos/tiendas')
                .then(r => {
                        if (r.data.Success){$this.optionsTiendas = r.data.Data;$this.optionsTiendasReal = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectTienda(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.tiendas = [];
                    $this.filters.tiendas.push({
                        "name":'TODAS',
                        "value":0,
                        "formato":0,
                    })
                }else{
                    $this.filters.tiendasReal = $this.filters.tiendas;
                }
            });
            if (selectedOption.length === 0){ $this.filters.tiendasReal = $this.filters.tiendas; }
        },
        getDepartamentos(){let $this = this;
            axios.post('/api/AdminCatalogos/departamentos')
                .then(r => {
                        if (r.data.Success){$this.optionsDepartamentos = r.data.Data;$this.optionsDepartamentosReal = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectDepartamento(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.departamento = [];
                    $this.filters.departamento.push({
                        "name":'TODOS',
                        "value":0,
                        "formato":0,
                    })
                }else{
                    $this.filters.departamentosReal = $this.filters.departamentos;
                }
            });
            if (selectedOption.length === 0){ $this.filters.departamentosReal = $this.filters.departamentos; }
            else{$this.optionsCategoriasReal = [];}
            this.filters.categorias = null;
            this.filters.departamentos.forEach(function (value,key){
                if (value.value === 0){
                    $this.selectTodosDepartamentos = true;
                    $this.filters.departamentosReal = $this.optionsDepartamentos;
                    $this.filters.categoriasReal = $this.optionsCategorias;
                    return;
                }
                $this.optionsCategorias.forEach(function (valueIn,keyIn){
                    if (parseInt(value.value) === parseInt(valueIn.departamento)){
                        $this.selectTodosDepartamentos = false;
                        $this.optionsCategoriasReal.push({name: '' + valueIn.name+ '', value:valueIn.value, departamento:''+valueIn.departamento+''});
                    }
                });
            });
        },
        getCategorias(){let $this = this;
            axios.post('/api/AdminCatalogos/categorias')
                .then(r => {
                        if (r.data.Success){$this.optionsCategorias = r.data.Data; $this.optionsCategoriasReal = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectCategoria(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.categorias = [];
                    $this.filters.categorias.push({
                        "name":'TODAS',
                        "value":0,
                        "departamento":0,
                    })
                }else{
                    $this.filters.categoriasReal = $this.filters.categorias;
                }
            });
            if (selectedOption.length === 0){ $this.filters.categoriasReal = $this.filters.categorias; }
        },
        getFabricantes(){let $this = this;
            axios.post('/api/AdminCatalogos/fabricantes')
                .then(r => {
                        if (r.data.Success){$this.optionsFabricantes = r.data.Data; $this.optionsFabricantesReal = r.data.Data;}
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        SelectFabricante(selectedOption, id){let $this = this;
            selectedOption.forEach(function (value,key){
                if (value.value === 0){
                    $this.filters.fabricantes = [];
                    $this.filters.fabricantes.push({
                        "name":'TODOS',
                        "value":0,
                    })
                }else{
                    $this.filters.fabricantesReal = $this.filters.fabricantes;
                }
            });

        },
    }
}
</script>

<style scoped>

</style>
