<template>
    <div>
        <v-select :multiple="false" :options="optionsProductos" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedProductos" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "productos",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedProductos:null,
            optionsProductos:[]
        }
    },
    mounted() {
        this.getGestores();
    },
    methods:{
        getGestores(){
            let $this = this;
            axios.post('/api/AdminCatalogos/productosSelect')
                .then(r => {
                        $this.optionsProductos = r.data;
                        //console.log(r);
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        setCambio(){
            this.$emit('cambioProducto');
        }
    }
}
</script>

<style scoped>

</style>
