<template>
    <div>
        <div class="text-center" v-if="shoLoad">
            <div class="spiner-example">
                <div class="sk-spinner sk-spinner-circle">
                    <div class="sk-circle1 sk-circle"></div>
                    <div class="sk-circle2 sk-circle"></div>
                    <div class="sk-circle3 sk-circle"></div>
                    <div class="sk-circle4 sk-circle"></div>
                    <div class="sk-circle5 sk-circle"></div>
                    <div class="sk-circle6 sk-circle"></div>
                    <div class="sk-circle7 sk-circle"></div>
                    <div class="sk-circle8 sk-circle"></div>
                    <div class="sk-circle9 sk-circle"></div>
                    <div class="sk-circle10 sk-circle"></div>
                    <div class="sk-circle11 sk-circle"></div>
                    <div class="sk-circle12 sk-circle"></div>
                </div>
                <b>REALIZANDO BUSQUEDA...</b>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="text-left">
                    <button class="btn btn-outline btn-success dim" type="button" v-show="!shoLoad"
                            data-effect="effect-just-me" data-toggle="modal" href="#myModal1" ref="newProducto"><i class="fa fa-plus"></i> ALTA PRODUCTO</button>
                </div>
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
        </div><br><br>
        <div class="row">
            <div class="col-md-10">
                <v-client-table :data="tableDataProductos" :columns="columnsProductos" :options="optionsProductos" class="table-responsive" ref="tableProductos">
                    <div slot="Cdgentpto" slot-scope="props">
                        {{props.row.Cdgentpto}}<br>
                        <i class="fas fa-trash-alt" style="cursor:pointer" @click="deletePto(props.row.Cdgentpto)"></i> &nbsp;&nbsp;&nbsp; <i class="fas fa-edit" style="cursor:pointer;color: blue" @click="initEdit(props.row.Cdgentpto)"></i>
                    </div>
                    <div slot="Pathimg" slot-scope="props">
                        <i class="la la-camera-retro" style="color: blue; cursor: pointer" @click="showImgPto(props.row.Pathimg,props.row.Nomentpto)"></i>
                    </div>
                </v-client-table>
            </div>
            <div class="col-md-2" >
                <transition name="fade">
                    <div class="text-center">
                        <img :src="pathImg" alt="img Producto" class="img-fluid" v-if="show"><br>
                        <p class="tx-bold">{{ptoNom}}</p>
                    </div>
                </transition>
            </div>
        </div>
        <div class="modal" id="myModal1" tabindex="-1" :class="{'show':shoModalAlta}" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg " role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">{{msgModal}}</h6><button id="close" aria-label="Close" class="close" data-dismiss="modal" type="button" @click="cancelAlta"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4">
                                <p><b>Código Producto</b></p>
                                <input :disabled="editPto" type="text" maxlength="50" placeholder="Ingrese codigo Producto"  class="form-control" v-model="codigoProducto"/>
                            </div>
                            <div class="col-md-4">
                                <p><b>Nombre Producto</b></p>
                                <input type="text" maxlength="50" placeholder="Ingrese codigo Producto"  class="form-control" v-model="nombreProducto"/>
                            </div>
                            <div class="col-md-4">
                                <p><b>Código Producto Moët</b></p>
                                <input type="text" maxlength="50" placeholder="Ingrese codigo Producto"  class="form-control" v-model="codigoProductoMoet"/>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-4">
                                <fabricantes-select :placeholderm="'Fabricante'" ref="Fabricantes" v-on:cambioFabricante="getMarcas"></fabricantes-select>
                            </div>
                            <div class="col-md-4">
                                <marcas-select :placeholderm="'Marca'" ref="Marcas"></marcas-select>
                            </div>
                            <div class="col-md-4">
                                <categorias-select :placeholderm="'Categoria'" ref="Categorias"></categorias-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <label><b>Imagen:</b></label><br>
                                    <input type="file" name="myImage" accept="image/png"  class="form-control" @change="handleImage"/>
                                </div><br>
                                <i class="fa fa-eye" style="color: #0e9aef;cursor:pointer" @click="imgvisible = !imgvisible" v-show="!imgvisible"> Mostrar Imagen</i>
                                <br>
                                <div v-show="imageProductoMoet !=='' & imgvisible">
                                    <i class="fa fa-eye" style="color: darkred;cursor:pointer" @click="imgvisible = !imgvisible"> Ocultar imagen</i>
                                    <img :src="imageProductoMoet" class="img img-responsive" alt="Imagen_Producto" />
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="cancelAlta"> Cancelar <i class="fa fa-times"></i></button>
                        <button type="button" class="btn btn-success" @click="saveAlta" v-if="!editPto"> Registrar  <i class="fa fa-save"></i></button>
                        <button type="button" class="btn btn-info" @click="saveEdit" v-else> Guardar Cambio  <i class="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "tableptos",
    data(){
        return{
            editPto: false,
            show: false,
            pathImg:'',
            msgModal: 'ALTA DE PRODUCTO',
            ptoNom:'',
            shoLoad:false,
            shoModalAlta:false,
            columnsProductos: ['Cdgentpto','Cdgmoet','Nomentpto','Departamento','Fabricante','Marca','Categoria','Fchcrt','Pathimg'],
            tableDataProductos: [],
            editableColumns:['Cdgentpto'],
            optionsProductos: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Cdgentpto','Nomentpto','Marca','Departamento','Categoria','Cdgmoet'],
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Cdgentpto: 'Código',
                    Nomentpto: 'Producto',
                    Cdgmoet: 'Moët',
                    Fchcrt: 'Alta',
                    Pathimg: 'Img',
                },
                columnsClasses: {
                    Cdgentpto: 'text-center font-bold text-danger',
                    Nomentpto: 'text-justify',
                    Cdgmoet: 'text-center font-bold text-info',
                    Fabricante: 'text-center',
                    Marca: 'text-center',
                    Departamento: 'text-center',
                    Categoria: 'text-center',
                    Fchcrt: 'text-center tx-10',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            nombreProducto:'',
            codigoProducto:'',
            codigoProductoMoet:'',
            imageProductoMoet:'',
            imgvisible: true,
        }
    },
    mounted() {
        this.getData();
    },
    methods:{
        toDataUrl(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
                var reader = new FileReader();
                reader.onloadend = function() {
                    callback(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        },
        findIdx(findId){let $this = this;
            var idn = 0;
            var a = 0;
            for(a;a<$this.tableDataProductos.length;a++){
                if ($this.tableDataProductos[a].Cdgentpto === findId){
                    idn = a;
                }
            }
            return idn;
        },
        saveEdit(){
            if (this.codigoProducto.length < 3){
                this.$toastMessage('error','Ingrese un código de producto valido',3000,'top-right');
            }
            else if (this.nombreProducto.length < 3){
                this.$toastMessage('error','Ingrese un nombre de producto valido',3000,'top-right');
            }
            else if (this.$refs.Fabricantes.selectedFabricantes === null){
                this.$toastMessage('error','Seleccione un fabricante',3000,'top-right');
            }
            else if (this.$refs.Marcas.selectedMarcas === null){
                this.$toastMessage('error','Seleccione una marca',3000,'top-right');
            }
            else if (this.$refs.Categorias.selectedCategorias === null){
                this.$toastMessage('error','Seleccione una categoria',3000,'top-right');
            }
            else{ let $this = this;
                axios.post('/api/Productos/peticiones',{solicitud:10, codigo: this.codigoProducto,
                    nombre:this.nombreProducto,moet:this.codigoProductoMoet, fabricante: this.$refs.Fabricantes.selectedFabricantes.value,
                    marca:this.$refs.Marcas.selectedMarcas.value,categoria:this.$refs.Categorias.selectedCategorias.value, imagen:this.imageProductoMoet})
                    .then(function (r){
                        if (r.data.Success){
                            $this.getData();
                            $this.$toastMessage('success',r.data.Mensaje,3000,'center');
                            $this.cancelAlta();
                        }
                        else{
                            $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');

                        }
                    });
            }
        },
        initEdit(id){ let $this = this;
            this.editPto = true;
            this.msgModal = 'EDITAR PRODUCTO';
            this.$refs.newProducto.click();
            var idx = this.findIdx(id);
            this.nombreProducto = this.tableDataProductos[idx].Nomentpto;
            this.codigoProducto = this.tableDataProductos[idx].Cdgentpto;
            this.codigoProductoMoet = this.tableDataProductos[idx].Cdgmoet;
            this.codigoProductoMoet = this.tableDataProductos[idx].Cdgmoet;
            this.toDataUrl(this.tableDataProductos[idx].Pathimg, function(myBase64) {
                //console.log(myBase64); // myBase64 is the base64 string
                $this.imageProductoMoet = myBase64;
            });
            $this.$refs.Fabricantes.selectedFabricantes = {label:this.tableDataProductos[idx].Fabricante,value:this.tableDataProductos[idx].Cveentfab}
            $this.$refs.Fabricantes.setCambio();
            $this.$refs.Marcas.selectedMarcas = {label:this.tableDataProductos[idx].Marca,value:this.tableDataProductos[idx].Cveentmar}
            $this.$refs.Marcas.setCambio();
            $this.$refs.Categorias.selectedCategorias = {label:this.tableDataProductos[idx].Categoria,value:this.tableDataProductos[idx].Cveentcat}
        },
        handleImage(e){let $this = this;
            const selectedImage = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function(event) {
                $this.imageProductoMoet = event.target.result;
            };
            reader.onerror = function(error) {
                alert(error);
            };
            reader.readAsDataURL(selectedImage);
        },
        deletePto(id){ let $this = this;
            axios.post('/api/Productos/peticiones',{solicitud: 8,id: id})
            .then(function (r){
                if (r.data.Success){
                    if (r.data.Total > 0){
                        $this.$swal.fire({
                            title: '¿Elimiar producto?',
                            text: 'Este prooducto tiene registros, si es eliminado, se eliminara toda la información. Registros: ' + r.data.Total,
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Eliminar',
                            cancelButtonText: 'Cancelar',
                            cancelButtonColor: '#ba0731',
                            confirmButtonColor: '#1ba631'

                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                axios.post('/api/Productos/peticiones',{solicitud: 9,id: id})
                                    .then(function (r){
                                        if (r.data.Success){
                                            $this.getData();
                                            $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                                        }
                                    }).
                                catch(function (error) {
                                    $this.$toastMessage('error','Error: ' + error + ' Reportar a IT',3000,'top-right');
                                });
                            }
                        });
                    }
                    else{
                        axios.post('/api/Productos/peticiones',{solicitud: 9,id: id})
                        .then(function (r){
                            if (r.data.Success){
                                $this.getData()
                                $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                            }
                        }).
                        catch(function (error) {
                            $this.$toastMessage('error','Error: ' + error + ' Reportar a IT',3000,'top-right');
                        });
                    }
                }
                else{
                    $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                }
            }).
            catch(function (error) {
                $this.$toastMessage('error','Error: ' + error + ' Reportar a IT',3000,'top-right');
            });
        },
        getMarcas(){
            var a = this.$refs.Fabricantes.selectedFabricantes;
            //console.log(a);
            if (a !== null){
                this.$refs.Marcas.fabricante = a.value;
                this.$refs.Marcas.getMarcas();
            }
            else{
                this.$refs.Marcas.selectedMarcas = null;
            }
        },
        showImgPto(img,producto){ let $this = this;
           this.pathImg='/theme/img/loads/pelota.gif';
           setTimeout(function(){
               $this.pathImg = img;
               $this.show = true;
               $this.ptoNom = producto;
           }, 700);

        },
        newProducto(){

        },
        getData(){ let $this = this; this.tableDataProductos = [];
            this.$refs.tableProductos.setLoadingState(true);
            axios.post('/api/Productos/peticiones',{solicitud:2})
                .then(function (r){
                    if (r.data.Success){
                        $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                        $this.tableDataProductos= r.data.Productos;
                        $this.$refs.tableProductos.setLoadingState(false);
                    }
                    else{
                        $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                        $this.$refs.tableProductos.setLoadingState(false);
                    }
                });
        },
        cancelAlta(){
            this.shoModalAlta = !this.shoModalAlta;
            this.nombreProducto='';
            this.codigoProducto='';
            this.codigoProductoMoet='';
            this.imageProductoMoet='';
            this.msgModal = 'ALTA DE PRODUCTO';
            this.$refs.Fabricantes.selectedFabricantes = null;
            this.$refs.Marcas.selectedMarcas = null;
            this.$refs.Categorias.selectedCategorias = null;
            document.getElementById('close').click();
        },
        saveAlta(){
            if (this.codigoProducto.length < 3){
                this.$toastMessage('error','Ingrese un código de producto valido',3000,'top-right');
            }
            else if (this.nombreProducto.length < 3){
                this.$toastMessage('error','Ingrese un nombre de producto valido',3000,'top-right');
            }
            else if (this.$refs.Fabricantes.selectedFabricantes === null){
                this.$toastMessage('error','Seleccione un fabricante',3000,'top-right');
            }
            else if (this.$refs.Marcas.selectedMarcas === null){
                this.$toastMessage('error','Seleccione una marca',3000,'top-right');
            }
            else if (this.$refs.Categorias.selectedCategorias === null){
                this.$toastMessage('error','Seleccione una categoria',3000,'top-right');
            }
            else{ let $this = this;
                axios.post('/api/Productos/peticiones',{solicitud:3, codigo: this.codigoProducto,
                nombre:this.nombreProducto,moet:this.codigoProductoMoet, fabricante: this.$refs.Fabricantes.selectedFabricantes.value,
                marca:this.$refs.Marcas.selectedMarcas.value,categoria:this.$refs.Categorias.selectedCategorias.value, imagen:this.imageProductoMoet})
                    .then(function (r){
                        if (r.data.Success){
                            $this.getData();
                            $this.$toastMessage('success',r.data.Mensaje,3000,'center');
                            $this.cancelAlta();
                        }
                        else{
                            $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');

                        }
                    });

            }

        }
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
}
</style>
