<template>
    <div>
        <v-select :multiple="true" :options="optionsMarcas" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedMarcas" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "marcas",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedMarcas:null,
            optionsMarcas:[],
            fabricantes:[]
        }
    },
    mounted() {
        this.getMarcas();
    },
    methods:{
        getMarcas(){
            let $this = this;
            axios.post('/api/AdminCatalogos/marcasSelectD',{fabricantes:this.fabricantes})
                .then(r => {
                        $this.optionsMarcas = r.data;
                        //console.log(r);
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        setCambio(){
            this.$emit('cambioMarca');
        }
    }
}
</script>

<style scoped>

</style>
